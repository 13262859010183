import React, { useCallback, useContext, useEffect, useState } from 'react';

import Page from 'components/common/Page';
import caseAPI from 'case/caseAPI';

import { Box, Drawer, Grid, TextField, Toolbar } from '@mui/material';
import T from '@mui/material/Typography';
import RadarChart from 'components/charts/RadarChart';
import { GridColDef, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import { DateTime } from 'luxon';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import KpiTable from 'components/common/KpiTable';
import CaseStatusFilter from './CaseStatusFilter';
import AssessmentStatusRawCases from 'components/charts/AssesmentStatusRawCases';
import PickListContext from 'pickList/pickListContext';
import ArrestedPieChart from './ArrestedPieChart';
import StatusPieChart from './StatusPieChart';
import DataGrid from 'components/common/StripedDataGrid';

interface IFilter {
	caseStartDate: DateTime | null;
	caseEndDate: DateTime | null;
	statuses: string[];
}

const SummaryReportPage = () => {

	const { pickListItems } = useContext(PickListContext);
	const [isLoading, setIsLoading] = useState(true);
	
	const [caseReports, setCaseReports] = useState<CaseReportDTO[]>([]);
	const [caseReportsFiltered, setCaseReportsFiltered] = useState<CaseReportDTO[]>([]);
	
	const [casesSummaryReport, setCasesSummaryReport] = useState<CaseSummaryReportDTO[]>([]);
	const [casesSummaryReportFiltered, setCasesSummaryReportFiltered] = useState<CaseSummaryReportDTO[]>([]);

	
	const initialFilter = {caseStartDate: null, caseEndDate:null, statuses:[]}
	const [filter, setFilter] = useState<IFilter>(initialFilter);

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);

			const caseReportProm = caseAPI.getCasesReports(true);
			const caseSummaryReportProm = caseAPI.getCasesSummaryReport();

			const caseReports = await caseReportProm;
			setCaseReports(caseReports);
			
			const casesSummaryReport = await caseSummaryReportProm;
			setCasesSummaryReport(casesSummaryReport);

			setIsLoading(false);
		}

		fetchData();
	}, []);

	
	const onCaseStatusFilterChange = useCallback((statusFilter: string[]) => {
		//setFilter({...filter, [e.target.name]: e.target.checked });
		setFilter(prevFilter => ({...prevFilter, statuses: statusFilter}));

	},[]);
	useEffect(() => {

		const filteredCaseReports = caseReports.filter(r => {
			let isMatch = true;
			isMatch = filter.statuses.includes(r.status!);

			if(r.date && filter.caseStartDate && filter.caseEndDate) {
				const caseDate = DateTime.fromISO(r.date);
				isMatch = isMatch && caseDate >= filter.caseStartDate && caseDate <= filter.caseEndDate;
			}
			else if(r.date && filter.caseStartDate) {
				const caseDate = DateTime.fromISO(r.date);
				isMatch = isMatch && caseDate >= filter.caseStartDate;

			}
			else if(r.date && filter.caseEndDate) {
				const caseDate = DateTime.fromISO(r.date);
				isMatch = isMatch && caseDate <= filter.caseEndDate;
			}
			
			return isMatch;
		});

		setCaseReportsFiltered(filteredCaseReports);		

		const filteredCasesSummary = casesSummaryReport.filter(r => {
			let isMatch = true;
			isMatch = filter.statuses.includes(r.status!);

			return isMatch;
		});
		setCasesSummaryReportFiltered(filteredCasesSummary);

	}, [filter, caseReports, casesSummaryReport]);
	
	const formatDate = (params: GridValueFormatterParams) => {
		if(params.value) {
			const formattedValue = DateTime.fromISO(params.value?.toString()).toLocaleString(DateTime.DATETIME_SHORT);
			return formattedValue;
		}
	}
	const getStatusDescription = (params: GridValueGetterParams) => {
		const plItem = pickListItems.find(pl => pl.id === params.row.statusId);
		return plItem ? plItem?.description : 'No data';
	}
	const columns: GridColDef[] = [
		{ field: 'caseIdentifier', headerName: 'Case #', flex: 1 },
		{ 
			field: 'statusId',
			headerName: 'State',
			valueGetter: getStatusDescription
		},
		{ field: 'officerName', headerName: 'Officer', flex: .8 },
		{ field: 'address', headerName: 'Location', flex:2 },
		{ field: 'date', headerName: 'Date', valueFormatter:formatDate, width: 150 },
	];

	return (
		<>
		<Page isLoading={isLoading} sx={{mr:'240px'}}>			
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<KpiTable cases={caseReportsFiltered} />
					</Grid>
					<Grid item md={6} display='none'>
						<AssessmentStatusRawCases caseReports={caseReportsFiltered} />
					</Grid>
					<Grid item md={6}>
						<T variant='h2'>Test Outcomes</T>
						<Box sx={{px:15}}>
							<ArrestedPieChart cases={caseReportsFiltered} />
						</Box>
					</Grid>
					<Grid item md={6}>
						<T variant='h2'>Test Status</T>
						<Box sx={{px:15}}>
							<StatusPieChart cases={caseReportsFiltered} />
						</Box>
					</Grid>
					<Grid item md={6} display='none'>
						<T variant='h2'>Test Outcomes</T>
						<RadarChart caseSummaryReport={casesSummaryReportFiltered} />
					</Grid>
					<Grid item md={12}>
						<DataGrid
							rows={caseReportsFiltered}
							columns={columns}
							autoHeight
							disableSelectionOnClick={true}
							getRowClassName={p => p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
						/>
					</Grid>
				</Grid>
				<Drawer variant='permanent' anchor='right' sx={{width:240, flexShrink:0, '& .MuiDrawer-paper': { width: 240,  boxSizing: 'border-box', my:3, mr:3, px:2} }} >
					<Toolbar />
					<T variant='h2'>Filters</T>
					<LocalizationProvider dateAdapter={AdapterLuxon}>
						<DatePicker
							label="Case Start"
							value={filter.caseStartDate}
							onChange={(newValue) => { setFilter({...filter, caseStartDate: newValue! }); }}
							renderInput={(params) => <TextField {...params} margin='normal' />}
						/>
						<DatePicker
							label="Case End"
							value={filter.caseEndDate}
							onChange={(newValue) => { setFilter({...filter, caseEndDate: newValue! }); }}
							renderInput={(params) => <TextField {...params} margin='normal' />}
						/>
					</LocalizationProvider>
					<CaseStatusFilter onFilterChange={onCaseStatusFilterChange} />
				</Drawer>
		</Page>
		</>
	);
}

export default SummaryReportPage;
