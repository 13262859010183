import { alpha, styled } from "@mui/material/styles";
import { gridClasses } from "@mui/x-data-grid";
import { DataGrid } from "@mui/x-data-grid/DataGrid";

const ODD_OPACITY = 0.2;
const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
	[`& .${gridClasses.row}.even`]: {
	  backgroundColor: theme.palette.grey[200],
	  '&:hover, &.Mui-hovered': {
		backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
		'@media (hover: none)': {
		  backgroundColor: 'transparent',
		},
	  },
	  '&.Mui-selected': {
		backgroundColor: alpha(
		  theme.palette.primary.main,
		  ODD_OPACITY + theme.palette.action.selectedOpacity,
		),
		'&:hover, &.Mui-hovered': {
		  backgroundColor: alpha(
			theme.palette.primary.main,
			ODD_OPACITY +
			  theme.palette.action.selectedOpacity +
			  theme.palette.action.hoverOpacity,
		  ),
		  // Reset on touch devices, it doesn't add specificity
		  '@media (hover: none)': {
			backgroundColor: alpha(
			  theme.palette.primary.main,
			  ODD_OPACITY + theme.palette.action.selectedOpacity,
			),
		  },
		},
	  },
	},
	[`& .${gridClasses.columnHeaders}`]: {
		backgroundColor: theme.palette.primary.main, 
		color: theme.palette.primary.light,
		'& .MuiDataGrid-sortIcon': {
			color: theme.palette.primary.light,
		},
		'& .MuiIconButton-root': {
			color: theme.palette.primary.light,
		},
	},
	[`& .${gridClasses.columnHeaderTitle}`]: {
		fontWeight: '900',
		fontSize: '1rem',
	},
  }));

  StripedDataGrid.defaultProps = {
	  initialState: {pagination: {pageSize:5}},
	  rowsPerPageOptions: [5,10,25,50,100]
  };

  export default StripedDataGrid;