import  React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { Alert, Snackbar } from '@mui/material';

import Page from 'components/common/Page';
import Title from 'components/common/Title';
import pickListAPI from 'pickList/pickListAPI';

const initialState: PickListItem = {
	code: '',
	description: '',
	isActive: false,
}

const PickListItemPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [successIsOpen, setSuccessIsOpen] = useState(false);
    const [errorIsOpen, setErrorIsOpen] = useState(false);
    const history = useHistory();

    const { itemId } = useParams<{itemId: string}>();
    const { pickListTypeId } = useParams<{pickListTypeId: string}>();
	const [pickListItem, setPickListItem] = useState<PickListItem>(initialState);

	useEffect(() => {
		const fetchData = async () => {
			if(itemId !== '0') {
				const data = await pickListAPI.getPickListItem(itemId);
				setPickListItem(data);
				setIsLoading(false);
			}
			else {				
				setPickListItem(pli => ({...pli, pickListTypeId: Number.parseInt(pickListTypeId)}) );
				setIsLoading(false);
			}
		};
	
		fetchData();
	}, [itemId, pickListTypeId]);

	const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setPickListItem(pli => ({...pli, [e.target.name]: e.target.value }));
	}
	const onCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPickListItem(pli => ({...pli, [e.target.name]: e.target.checked }));
	}
	const saveItem = async() => {
		setIsLoading(true);
		try{
			await pickListAPI.savePickListItem(pickListItem);
			setSuccessIsOpen(true);

			setTimeout(() => {
				history.push(`/admin/pick-lists/${pickListTypeId}/items`);
			}, 1000);
        } 
        catch {
            setErrorIsOpen(true);
        }
        finally {
            setIsLoading(false);
        }
	}

	return (
		<Page>
			<Title>
				{itemId === '0'
					? 'Add new pick list item'
					: `Edit ${pickListItem.description}`
				}
			</Title>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12}>
					<TextField
						fullWidth 
						name="code"
						label="Code" 
						value={pickListItem?.code} 
						onChange={onTextChange} 
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField 
						fullWidth 
						name="description" 
						label="Description" 
						value={pickListItem?.description} 
						onChange={onTextChange} 
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<FormControlLabel
						label="Is Active"
						control={<Checkbox
							name="isActive"
							checked={pickListItem?.isActive}
							onChange={onCheckChange}
						/>}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<Button variant='contained' color='secondary' onClick={saveItem} disabled={isLoading}>Save</Button>
				</Grid>
			</Grid>
			

            <Snackbar open={successIsOpen} autoHideDuration={6000} onClose={() => setSuccessIsOpen(false)}>
                <Alert severity="success" onClose={() => setSuccessIsOpen(false)}>The user details were saved! Redirecting...</Alert>
            </Snackbar>
            <Snackbar open={errorIsOpen} autoHideDuration={6000} onClose={() => setErrorIsOpen(false)}>
                <Alert severity="error" onClose={() => setErrorIsOpen(false)}>There was an error saving!</Alert>
			</Snackbar>
		</Page>
		// <Page title={title}>
		// 	<div className="workflow-progress-wrapper">
		// 		<div className="container">
		// 			<h1 className="h2 m-0">{title}</h1>
		// 		</div>
		// 	</div>
		// 	<section className="features-1 text-left">
		// 		<div className="container">
		// 		{/* replace pickList item form with antd form with a similar pattern and style  */}
		// 			{/* <PickListItemForm
		// 				pickListItem={this.props.pickListItem}
		// 				onChange={this.onChange}
		// 			/>
		// 			<Button onClick={this.onSave} disabled={this.state.isLoading} className="btn-primary">
		// 				Save
		// 			</Button>
		// 			<IconLinkButton to={`/admin/pick-lists/${this.props.match.params.pickListTypeId}`} >Cancel</IconLinkButton> */}
		// 		</div>
		// 	</section>
		// </Page>
	);

	// private onSave = () => {
	// 	this.setState({ isLoading: true });
	// 	this.props.actions.savePickListItem(this.props.pickListItem)
	// 		.then(() => this.setState({ isLoading: false }))
	// 		.then(() => {
	// 			//toastr.success('Pick List Item saved');
	// 			this.props.history.push(`admin/pick-lists/${this.props.match.params.pickListItemId}`);
	// 		});
	// }
}

export default PickListItemPage;
