import React from 'react';

interface IProps {
	answers: YesNoRecordDTO[];
	testId?: string;
	subjectName: string;
}
const YesNoAnswerList = (props: IProps) => {
	const renderYesNoAnswers = (yesNoAnswers: YesNoRecordDTO[], testId?: string) => {
		if (yesNoAnswers.length === 0) {
			return (
				<ul className="mb-3 pb-2">
					<li className="font-italic">No record found.</li>
				</ul>
			);
		}
		return (
			<ul className="mb-3 pb-2">
				{
					yesNoAnswers.filter( record => record.testId === testId)
					.map( record  => {
						return (
							<li key={record.id}>
								<strong>{props.subjectName}</strong> answered {record.yes ? 'Yes ' : 'No '}
								at {new Date(record.date!).toLocaleTimeString('en-us')}.
							</li>
						);
					})
				}
			</ul>
		);
	};
	return (
		<>
			{ renderYesNoAnswers(props.answers, props.testId) }
		</>
	);
}
export default YesNoAnswerList;