import React, { useContext, useEffect, useState } from 'react';

import { FormControlLabel, Grid, Switch, TextField } from '@mui/material';
import T from '@mui/material/Typography';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import { DateTime } from 'luxon';
import GoogleMap from 'google-map-react';

import Page from 'components/common/Page';
import PickListContext from 'pickList/pickListContext';
import caseAPI from 'case/caseAPI';

import CaseMapMarker from './CaseMapMarker';

import { greyScaleMapStyles } from 'common/utils';
//import { debounce } from 'lodash';

const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY!;
const styles = greyScaleMapStyles;
interface IProps {
	casesReports: CaseReportDTO[];
	actions: any;

}
const CaseReportPage = (props:IProps) => {

	const {pickListItems} = useContext(PickListContext);
	const [caseReports, setCaseReports] = useState<CaseReportDTO[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const initialFilter = {caseIdentifier: '', caseStartDate: null, caseEndDate:null, officerName: '', submitted: true, finalized: true}
	const [filter, setFilter] = useState(initialFilter);


	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);

			const caseReportProm = caseAPI.getDemoCasesReports();

			const caseReports = await caseReportProm;
			setCaseReports(caseReports);

			setIsLoading(false);
		}

		fetchData();
	}, []);
	const onFilterCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFilter({...filter, [e.target.name]: e.target.checked });
	};
	const onFilterChanged = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setFilter({...filter, [e.target.name]: e.target.value });
	}
	const submittedStatus = pickListItems.find((p: PickListItemResponseDTO) => p.code === 'Submitted');
	const finalizedStatus = pickListItems.find((p: PickListItemResponseDTO) => p.code === 'Finalized');
	
	const renderMarkers = (caseReports: CaseReportDTO[]) => {		
		//todo: consider debouncing the filtering and putting the filtered cases into a separate object so we can provide a count
		return caseReports
		.filter(r => {
			let isMatch = true;
			if(filter.caseIdentifier && r.caseIdentifier) {
				isMatch = r.caseIdentifier.toLowerCase().includes(filter.caseIdentifier.toLowerCase());
			}
			if(filter.officerName && r.officerName) {
				isMatch = isMatch && r.officerName.toLowerCase().includes(filter.officerName.toLowerCase());
			}

			if(r.date && filter.caseStartDate && filter.caseEndDate) {
				const caseDate = DateTime.fromISO(r.date);
				isMatch = isMatch && caseDate >= filter.caseStartDate && caseDate <= filter.caseEndDate;
			}
			else if(r.date && filter.caseStartDate) {
				const caseDate = DateTime.fromISO(r.date);
				isMatch = isMatch && caseDate >= filter.caseStartDate;

			}
			else if(r.date && filter.caseEndDate) {
				const caseDate = DateTime.fromISO(r.date);
				isMatch = isMatch && caseDate <= filter.caseEndDate;
			}

			if(filter.submitted && filter.finalized) { // show if this is a submitted case
				isMatch = isMatch && (r.statusId === submittedStatus!.id || r.statusId === finalizedStatus!.id);
			}
			else if(filter.submitted) {
				isMatch = isMatch && r.statusId === submittedStatus!.id;
			}
			else if(filter.finalized) {
				isMatch = isMatch && r.statusId === finalizedStatus!.id;
			}
			else if (!filter.finalized && !filter.submitted) {
				isMatch = false;
			}
			
			return isMatch;
			
		})
		.map(r => {
			const formattedDate = DateTime.fromISO(r.date!).toLocaleString(DateTime.DATETIME_SHORT);
			return (
				<CaseMapMarker
					key={r.id}
					lat={r.latitude!}
					lng={r.longitude!}
					caseIdentifier={r.caseIdentifier!}
					caseDate={formattedDate}
					officerName={r.officerName!}
					finalized={isFinalized(r.statusId!)}
				/>
			);
		});
	}
	const isFinalized = (statusId: number) => {
		// context takes time to pass pickListItems into current component?? set network to slow to replicate the issue
		if (pickListItems.length === 0) {
			return false;
		}
		const statusItem = pickListItems.find((p: PickListItemResponseDTO) => p.id === statusId);
		if (statusItem && statusItem.code === 'Finalized') {
			return true;
		}
		return false;
	}
		
		// Using different Component so that it uses different keys
	//TODO: change this to dynamic centering based on the cases
	const centerOfYRP = {
		lat: 43.9,
		lng: -79.59
	};

	return (
		<Page isLoading={isLoading}>
			<Grid container spacing={2} sx={{pb:12}}>
				<Grid item md={9} sx={{height:'80vh', width:'100%'}}>
					<T variant='h2'>Map</T>
					<GoogleMap
						bootstrapURLKeys={{ key: googleApiKey }}
						defaultCenter={centerOfYRP}
						defaultZoom={10}
						yesIWantToUseGoogleMapApiInternals={true}
						options={{ styles }}
					>
						{renderMarkers(caseReports)}
					</GoogleMap>
				</Grid>
				<Grid item md={3}>
					<T variant='h2'>Filters</T>
					<TextField
						fullWidth
						margin='normal'
						name="caseIdentifier"
						label="Case #"
						value={filter.caseIdentifier}
						onChange={evt => onFilterChanged(evt) }
					/>
					<LocalizationProvider dateAdapter={AdapterLuxon}>
						<DatePicker
							label="Case Start"
							value={filter.caseStartDate}
							onChange={(newValue) => { setFilter({...filter, caseStartDate: newValue! }); }}
							renderInput={(params) => <TextField {...params} margin='normal' />}
						/>
						<DatePicker
							label="Case End"
							value={filter.caseEndDate}
							onChange={(newValue) => { setFilter({...filter, caseEndDate: newValue! }); }}
							renderInput={(params) => <TextField {...params} margin='normal' />}
						/>
					</LocalizationProvider>
					<TextField 
						fullWidth
						margin='normal'
						name="officerName"
						label="Officer"
						value={filter.officerName}
						onChange={onFilterChanged}
					/>
					<FormControlLabel 
						label="Show Submitted"
						control={<Switch 
							name="submitted" 
							checked={filter.submitted}
							onChange={onFilterCheckChanged}
							/>}
					/>
					<FormControlLabel 
						label="Show Finalized"
						control={<Switch 
							name="finalized" 
							checked={filter.finalized}
							onChange={onFilterCheckChanged}
							/>}
					/>
					
				</Grid>
			</Grid>
		</Page>
	);

}

export default CaseReportPage;