import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Grid, TextField } from '@mui/material';
import PickListContext from 'pickList/pickListContext';

interface IDistrictPlatoonListProps {
	user: UserDetailsDTO;
	onDistrictChanged: (value: PickListItemResponseDTO | null) => void;
	onPlatoonChanged: (value: PickListItemResponseDTO | null) => void;
}
const DistrictPlatoonLists = (props: IDistrictPlatoonListProps) => {

	const [selectedDistrict, setSelectedDistrict] = useState<PickListItemResponseDTO | null>(null);
	const [filteredPlatoons, setFilteredPlatoons] = useState<PickListItemResponseDTO[]>([]);
	const [selectedPlatoon, setSelectedPlatoon] = useState<PickListItemResponseDTO | null>(null);

	const picklistContext = useContext(PickListContext);
	const districts = useMemo(() => picklistContext.pickListItems.filter(pi => pi.pickListTypeCode === 'district'), [picklistContext.pickListItems]);
	const allPlatoons = useMemo(() => picklistContext.pickListItems.filter(pi => pi.pickListTypeCode === 'platoon'), [picklistContext.pickListItems]);

	useEffect(() => {
		if (props.user.id && districts.length > 0) {
			const selectedDistrict = districts.find(pi => pi.id === props.user.districtId);
			setSelectedDistrict(selectedDistrict || null);

			const filteredPlatoons = allPlatoons.filter(pi => pi.parentId === selectedDistrict?.id);
			setFilteredPlatoons(filteredPlatoons);
		}

	}, [props.user, districts, allPlatoons]);
	useEffect(() => {
		if(props.user.id && filteredPlatoons.length > 0) {
			const selectedPlatoon = filteredPlatoons.find(pi => pi.id === props.user.platoonId);
			setSelectedPlatoon(selectedPlatoon || null);
		}
	}, [props.user, filteredPlatoons]);

	const handleDistrictChange = (event: React.SyntheticEvent<Element, Event>, value: PickListItemResponseDTO | null) => {
		setSelectedDistrict(value);
		props.onDistrictChanged(value);

		const filteredPlatoons = allPlatoons.filter(pi => pi.parentId === value?.id);
		setFilteredPlatoons(filteredPlatoons);
	};
	const handlePlatoonChange = (event: React.SyntheticEvent<Element, Event>, value: PickListItemResponseDTO | null) => {
		setSelectedPlatoon(value);
		props.onPlatoonChanged(value);
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={6} lg={3}>
				<Autocomplete
					options={districts}
					getOptionLabel={(option) => option.description!}
					value={selectedDistrict}
					onChange={handleDistrictChange}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Districts" />
					)} />
			</Grid>
			<Grid item xs={12} sm={6} lg={3}>
				<Autocomplete
					options={filteredPlatoons}
					getOptionLabel={(option) => option.description!}
					value={selectedPlatoon}
					onChange={handlePlatoonChange}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Platoon" />
					)} />
			</Grid>
		</Grid>
	);
};
export default DistrictPlatoonLists;