import React, { useEffect, useState } from 'react';

import { Box, Divider, Grid } from '@mui/material';
import T from '@mui/material/Typography';

import StatusLineGraph from 'report/StatusLineGraph';
import GoogleMap from 'components/charts/GoogleMap/Googlemap';

import caseAPI from 'case/caseAPI';
import Page from 'components/common/Page';
import Title from 'components/common/Title';
import KpiTable from 'components/common/KpiTable';
import ArrestedPieChart from 'report/ArrestedPieChart';

interface IProps {
	className?: string;
	caseSummaryReport: CaseSummaryReportDTO[];
	actions: any;
	casesReports: CaseReportDTO[];
	heatMapData: [];
}
const LandingPage = (props: IProps) => {
	
	const [caseReports, setCaseReports] = useState<CaseReportDTO[]>([]);
	const [caseSummaryReports, setCaseSummaryReports] = useState<CaseSummaryReportDTO[]>([]);

	useEffect(() => {
		const fetchCase = async () => {
			const caseReportsProm = caseAPI.getCasesReports();
			const caseSummaryProm = caseAPI.getCasesSummaryReport();

			const caseReports = await caseReportsProm;
			setCaseReports(caseReports)
			const caseSummaryReports = await caseSummaryProm;
			setCaseSummaryReports(caseSummaryReports);
		};
	
		fetchCase();
	}, []);

	return (
		<Page>
			<Title>SFST Platform</Title>
			<KpiTable cases={caseReports} />
			<Divider sx={{my:5}} />
			<Grid container spacing={2}>
				<Grid item xs={12} md={6} minHeight='400px'>
					<T variant='h2'>Test Summary</T>
					<StatusLineGraph cases={caseSummaryReports} />
				</Grid>
				<Grid item xs={12} md={6}>
					<T variant='h2'>Test Outcomes</T>
					<Box sx={{px:15}}>
						<ArrestedPieChart cases={caseReports} />
					</Box>
				</Grid>
				<Grid item xs={12}>
					<GoogleMap
						heatMapData={caseReports}
						fitBounds={true}
					/>
				</Grid>
			</Grid>
		</Page>
	);

}

export default LandingPage;