import React from 'react';

import {
	Chart as ChartJS,
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip,
	Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';

ChartJS.register(
	RadialLinearScale,
	PointElement,
	LineElement,
	Filler,
	Tooltip,
	Legend
);


interface IProps {
	caseSummaryReport: CaseSummaryReportDTO[];
	options?: any;
	title?: string;
}
const RadarChart: React.FunctionComponent<IProps> = ({ caseSummaryReport, options, title }) => {

	const renderRadarData = () => {

		const statusArr: any[] = [];
		const statusCount: number[] = [];
		caseSummaryReport.forEach(caseSummary => {
			statusArr.push(caseSummary.status);
			let count = 0;
			if (caseSummary.data) {
				caseSummary.data.forEach(d => count += d.count || 0);
			}
			statusCount.push(count);
		});

		return {
			labels: statusArr,
			datasets: [{
				data: statusCount,
				label: 'Case Summary Report',
				backgroundColor: 'rgba(200,0,0,0.2)',
			}]
		};
	};
	const defaultOptions = {
		responsive: true,
	};
	return (
		<Radar
			data={renderRadarData()}
			options={{
				...defaultOptions,
				...options,
			}}
		/>
	);
};

export default RadarChart;
