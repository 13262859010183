import { SxProps, Theme, Typography } from '@mui/material';
import React from 'react';

interface TitleProps {    
    children?: React.ReactNode;
    /**
    * The system prop that allows defining system overrides as well as additional CSS styles.
    */
    sx?: SxProps<Theme>;
  }
const Title = (props: TitleProps) => {
    return (
        <Typography sx={props.sx} component="h2" variant="h6" color="primary" gutterBottom marginBottom={2}>
            {props.children}
        </Typography>
    );
}
export default Title;