export const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

/**
 * @param {number} month
 * @returns {array}
 * @description eg. input month =4 , returns [4,5,6,7,8,9,10,11,12,1,2,3]
 */
export const sortDateStartingFromCurrent = (month: number) => {
	const numericMonths = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
	if (month > 12) {
		month = month - 12;
	}
	const sortedMonth: number[] = [];
	const pastMonth: number[] = [];
	numericMonths.forEach(m => {
		if (m - month < 0) {
			pastMonth.push(m);
		} else {
			sortedMonth.push(m);
		}
	});
	const result = [ ...sortedMonth, ...pastMonth];
	return result;
};

export const renderPercentage = (count: number, sum: number) => (Math.round(count / sum * 10000) / 100 + '%');

export const centerOfEdmonton = {
	latitude: 53.54,
	longitude: -113.48
};

export const greyScaleMapStyles = [
	{ featureType: 'water', elementType: 'geometry', stylers: [{ color: '#e9e9e9' }, { lightness: 17 }] },
	{ featureType: 'landscape', elementType: 'geometry', stylers: [{ color: '#f5f5f5' }, { lightness: 20 }] },
	{ featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }, { lightness: 17 }] },
	{ featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }] },
	{ featureType: 'road.arterial', elementType: 'geometry', stylers: [{ color: '#ffffff' }, { lightness: 18 }] },
	{ featureType: 'road.local', elementType: 'geometry', stylers: [{ color: '#ffffff' }, { lightness: 16 }] },
	{ featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#f5f5f5' }, { lightness: 21 }] },
	{ featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#dedede' }, { lightness: 21 }] },
	{ elementType: 'labels.text.stroke', stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }] },
	{ elementType: 'labels.text.fill', stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }] },
	{ elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
	{ featureType: 'transit', elementType: 'geometry', stylers: [{ color: '#f2f2f2' }, { lightness: 19 }] },
	{ featureType: 'administrative', elementType: 'geometry.fill', stylers: [{ color: '#fefefe' }, { lightness: 20 }] },
	{ featureType: 'administrative', elementType: 'geometry.stroke', stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }] }];

// custom goolemap heatmap layer
export const gradient = [
	'rgba(238, 11, 49,0)',
	'rgba(236, 64, 64,1)',
	'rgba(127, 0, 255, 1)',
	'rgba(63, 0, 255, 1)',
	'rgba(0, 0, 255, 1)',
	'rgba(0, 0, 223, 1)',
	'rgba(0, 0, 191, 1)',
	'rgba(0, 0, 159, 1)',
	'rgba(0, 0, 127, 1)',
	'rgba(0, 63, 91, 1)',
	'rgba(0, 127, 63, 1)',
	'rgba(0, 191, 31, 1)',
	'rgba(0, 255, 0, 1)',
];
