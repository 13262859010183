import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const pickListAPI = {
	getPickListItem: (itemId?: string) => {
		return axios.get(`${apiUrl}/pick-list-items/${itemId}`)
			.then(response => response.data as PickListItem);
	},
	getPickListItems: () => {
		return axios.get(`${apiUrl}/pick-list-items`)
			.then(response => response.data as PickListItem[]);
	},
	getPickListType: (id: string) => {
		return axios.get(`${apiUrl}/pick-list-types/${id}`)
			.then(response => response.data as PickListType);
	},
	getPickListTypes: () => {
		return axios.get(`${apiUrl}/pick-list-types`) // todo: see if axios or an interceptor can auto add the apiUrl.
			.then(response => response.data as PickListType[]);
	},
	savePickListItem: (item: PickListItem) => {
		return axios.post(`${apiUrl}/pick-list-items`, item)
			.then(response => response.data as PickListItem);
	},
	savePickListType: (type: PickListType) => {
		return axios.post(`${apiUrl}/pick-list-types`, type)
			.then(response => response.data as PickListType);
	},
	getPickListItemByType: (code: string): Promise<PickListItem[]> => {
		return axios.get(`${apiUrl}/pick-list-items/type/${code}`)
			.then(res => res.data);
	},
	getPickListItemByTypeId: (id: string): Promise<PickListItem[]> => {
		return axios.get(`${apiUrl}/pick-list-types/${id}/items`)
			.then(res => res.data as PickListItem[]);
	}
};

export default pickListAPI;