import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const authAPI = {
	getCurrentUser: () => {
		return axios.get(`${apiUrl}/current-user`)
			.then(response => response.data as CurrentUserDTO);
	},
	getRoles: () => {
		return axios.get(`${apiUrl}/roles`)
			.then(response => response.data as RoleDTO[]);
	},
	getRole: (request: GetRoleRequest) => {
		return axios.get(`${apiUrl}/roles/${request.id}`)
			.then((response) => response.data as RoleDTO);
	},
	postRole: (request: PostRoleRequest) => {
		return axios.post(`${apiUrl}/roles`, request)
			.then((response) => response.data as RoleDTO);
	},
	getPermissions: () => {
		return axios.get(`${apiUrl}/permissions`)
			.then(response => response.data as PermissionDTO[]);
	}
};

export default authAPI;