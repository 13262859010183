import React, { useState } from 'react';
import Page from 'components/common/Page';
import Title from 'components/common/Title';
import { Box, Button } from '@mui/material';

const PromiseTestPage = () => {
    const [msgs, setMsgs] = useState<Array<string>>([]);

    const startTest = async () => {
        // await forAwaitTest();

        // await promiseAllTest();

        // await parallelTest();
        
        await doubleAwait();
    }

    const doubleAwait = async () => {
        const msg = `${getTimestamp()} ------------- double await started`;
        console.log(msg);

        const aProm = runTask('DA A', 2000);
        const bProm = runTask('DA B', 1000);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const b = await bProm;
        console.log(`${getTimestamp()} b done, adding c`);
        const cProm = runTask('DA C', 1000);

        var tasks = await Promise.all([aProm, bProm, cProm]);
        console.log('double-await', tasks[0]);
        console.log('double-await', tasks[1]);
        console.log('double-await', tasks[2]);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const forAwaitTest = async () => {
        const msg = `${getTimestamp()} ------------- for await test started`;
        console.log(msg);
        setMsgs([...msgs, msg]);

        const aProm = runTask('task A', 3000);
        const bProm = runTask('task B', 1000);

        for await (const task of [aProm, bProm]) {
            console.log('for-await', task);
            setMsgs([...msgs, task]);
        }  
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const promiseAllTest = async () => {
        const msg = `${getTimestamp()} ------------- promise all started`;
        console.log(msg);
        setMsgs([...msgs, msg]);

        const cProm = runTask('task C', 3000);
        const dProm = runTask('task D', 1000);



        const tasks = await Promise.all([cProm, dProm]);
        console.log('promise-all', tasks[0]);
        console.log('promise-all', tasks[1]);
    }
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const parallelTest = async () => {
        const msg = `${getTimestamp()} ------------- parallel test started`;
        console.log(msg);
        setMsgs([...msgs, msg]);

        const eProm = runTask('task E', 3000);
        const fProm = runTask('task F', 1000);

        const asyncLog = async(prom: Promise<string>) => {
            const task = await prom;
            console.log('parallel', task);
        };

        await Promise.all([asyncLog(eProm), asyncLog(fProm)]);

    }

    const runTask = async (title: string, timeout: number): Promise<string> => {
        return new Promise(resolve => setTimeout(() => {
            const msg = `${getTimestamp()} - ${title}`;
            console.log(msg);
            resolve(msg);
        }, timeout));
    }

    const getTimestamp = () => {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2,'0');
        
        return `${hours}:${minutes}:${seconds} ${now.getMilliseconds()}`;
    }

    return (
        <Page>
            <Title>Promise test</Title>
            <Button variant='contained' onClick={startTest}>Start Test</Button>
            <hr />
            {msgs.map((msg, i) => 
                <Box key={i}>{msg}</Box>
            )}
        </Page>
    );
}
export default PromiseTestPage;