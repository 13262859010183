import { Alert, Box, Button, Checkbox, FormControlLabel, Grid, Snackbar, TextField } from '@mui/material';
import Page from 'components/common/Page';
import Title from 'components/common/Title';
import pickListAPI from 'pickList/pickListAPI';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const plTypeDefault: PickListType = {
    code: '',
    description: '',
    id: 0,
    isActive: false    
};

const PickListTypeDetailsPage = () => {
    const [plType, setPlType] = useState<PickListType>(plTypeDefault);
    const [successIsOpen, setSuccessIsOpen] = useState(false);
    const [errorIsOpen, setErrorIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { pickListTypeId } = useParams<{pickListTypeId: string}>();
    const history = useHistory();

    useEffect(() => {
        const fetchType = async () => {
            try {
                setIsLoading(true);
                const type = await pickListAPI.getPickListType(pickListTypeId);
                setPlType(type);
            }
            finally {
                setIsLoading(false);
            }
		}

		fetchType();
    }, [pickListTypeId]);

    const saveItem = async () => {
        setIsLoading(true);
        console.log('save this: ', plType);
        try {
            await pickListAPI.savePickListType(plType);
            setSuccessIsOpen(true);
			
			setTimeout(() => {
				history.push('/admin/pick-lists');
			}, 1000);
            
        } 
        catch {
            setErrorIsOpen(true);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (
        <Page>
            <Title>
                PickList Type Details
            </Title>
                    
            <Box maxWidth="sm">
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12}>
                        <TextField 
                            id="code"
                            fullWidth 
                            label="Code" 
                            value={plType?.code} 
                            onChange={(e) => setPlType({...plType, code: e.target.value })} 
                        />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <TextField id="description" fullWidth label="Description" value={plType?.description} onChange={(e) => setPlType({...plType, description: e.target.value })} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControlLabel control={<Checkbox />} id="isactive" label="Is Active" checked={plType.isActive} onChange={(e) => setPlType({...plType, isActive: (e.target as HTMLInputElement).checked}) } />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <Button variant='contained' color='secondary' onClick={saveItem} disabled={isLoading}>Save</Button>
                    </Grid>
                </Grid>
            </Box>

            <Snackbar open={successIsOpen} autoHideDuration={6000} onClose={() => setSuccessIsOpen(false)}>
                <Alert severity="success" onClose={() => setSuccessIsOpen(false)}>The pick list type saved!</Alert>
            </Snackbar>
            <Snackbar open={errorIsOpen} autoHideDuration={6000} onClose={() => setErrorIsOpen(false)}>
                <Alert severity="error" onClose={() => setErrorIsOpen(false)}>There was an error saving!</Alert>
            </Snackbar>
        </Page>
    );
}
export default PickListTypeDetailsPage;