import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;
const userAPI = {
	// new methods
	getUsers: () => {
		return axios.get(`${apiUrl}/tenant/1/all-users`)
			.then((response) => response.data as UserDetailsDTO[]);
	},
	getUser: (userId: string) => {
		return axios.get(`${apiUrl}/tenant/1/users/${userId}`)
			.then((response) => response.data as UserDetailsDTO);
	},
	saveUser: (request: UserDetailsDTO) => {
		return axios.post(`${apiUrl}/tenant/1/users`, request)
			.then((response) => response.data as UserDetailsDTO);
	},
	// getTenantUserEmails: (request: GetTenantUserEmailsRequest) => {
	// 	return axios.get(`${apiUrl}/tenant/${request.tenantId}/user-emails`)
	// 		.then((response) => response.data as string[]);
	// },
};

export default userAPI;