import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const walkAndTurnTestAPI = {
	getWalkAndTurnTest: (caseId: string) => {
		return axios.get(`${apiUrl}/cases/${caseId}/walk-and-turn-test`)
			.then((response) => response.data as WalkAndTurnTestDTO[]);
	},
	postWalkAndTurnTest: (request: PostWalkAndTurnTestRequest) => {
		return axios.post(`${apiUrl}/cases/${request.caseId}/walk-and-turn-test`, request)
			.then((response) => response.data as WalkAndTurnTestDTO);
	},
	getWalkAndTurnTestItems: (caseId: string) => {
		return axios.get(`${apiUrl}/cases/${caseId}/walk-and-turn-test-items`)
			.then((response) => response.data as WalkAndTurnTestItemDTO[]);
	},
	getWalkAndTurnTestItem: (caseId: string, id: number) => {
		return axios.get(`${apiUrl}/cases/${caseId}/walk-and-turn-test-items/${id}`)
			.then((response) => response.data as WalkAndTurnTestItemDTO);
	},
	postWalkAndTurnTestItem: (request: PostWalkAndTurnTestItemRequest) => {
		return axios.post(`${apiUrl}/cases/${request.caseId}/walk-and-turn-test-items`.concat(request.id ? `/${request.id}` : ``), request)
			.then((response) => response.data as WalkAndTurnTestItemDTO);
	}
};

export default walkAndTurnTestAPI;