import React from 'react';

import TextField from '@mui/material/TextField';
import T from '@mui/material/Typography';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { WalkandTurnStepResults } from './WalkandTurnStepResults';
import YesNoAnswerList from './YesNoAnswerList';
import DateUpdated from './DateUpdated';

interface IProps {
	caseInfo: CaseDTO;
	watTestResult: WalkAndTurnTestDTO[];
	watTestResultItems: WalkAndTurnTestItemDTO[];
	watDoNothing: YesNoRecordDTO[];
	watInitialFootPosition: YesNoRecordDTO[];
	watInstructions: YesNoRecordDTO[];
	submittedCase: boolean;
	subjectName: string;
	onNotesChanged: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
}
const CaseDetailWAT = (props: IProps) => {
	const { caseInfo, subjectName, submittedCase, watTestResult, watTestResultItems } = props;

	const renderOneWalkAndTurnTest = (WalkAndTurnTest: WalkAndTurnTestDTO, index: number) => {
		const currentWATTestResultItems = watTestResultItems.filter(item => item.walkAndTurnTestId === WalkAndTurnTest.id);
		const turnedStepIndex = currentWATTestResultItems.findIndex(item => item.turned === true);
		const stepAdjustment = (turnedStepIndex !== -1 && currentWATTestResultItems[turnedStepIndex].step !== undefined && currentWATTestResultItems[turnedStepIndex].step! > 0) ? currentWATTestResultItems[turnedStepIndex].step : 0;
		const firstSetSteps = turnedStepIndex > 0 ? currentWATTestResultItems.slice(0,turnedStepIndex + 1) : currentWATTestResultItems;
		const returnSetSteps = turnedStepIndex !== -1 ? currentWATTestResultItems.slice(turnedStepIndex + 1) : [];

		const deserializedLostBalance: string[] = JSON.parse(WalkAndTurnTest.serializedLostBalance || '[]');
		const LeftFootLostLeftBalance = deserializedLostBalance.filter(x => x === "leftleft");
		const LeftFootLostRightBalance = deserializedLostBalance.filter(x => x === "leftright");
		const RightFootLostLeftBalance = deserializedLostBalance.filter(x => x === "rightleft");
		const RightFootLostRightBalance = deserializedLostBalance.filter(x => x === "rightright");

		return (
			<>
				<T variant='h3'>
					{WalkAndTurnTest.resetDate ? `Incomplete Attempt ${index + 1}` : 'Completed Attempt'}
				</T>
				<T variant='h4'>Setup</T>
				<T variant='h6' component='h5'>Response to Instructions: </T>
				<T fontStyle='italic'>
					Please stand with your heels and toes together, and your arms at your sides. (demonstrate) Do not do anything until I tell you to. Do you understand?
				</T>
				<YesNoAnswerList answers={props.watDoNothing} testId={WalkAndTurnTest.id} subjectName={subjectName} />
				<T fontStyle='italic'>
					Please put your left foot on the line. Now put your right foot in front of it, with your right heel touching your left toe. (demonstrate) Do not begin until I tell you to. Do you understand?
				</T>
				<YesNoAnswerList answers={props.watInitialFootPosition} testId={WalkAndTurnTest.id} subjectName={subjectName} />
				<T fontStyle='italic'>
					When I say “begin”, take 9 heel-to-toe steps down the line, turn around, and take 9 heel-to-toe steps back. Make your turn by keeping your front foot on the line, and then use your other foot to turn yourself around with several small steps. (demonstrate)
				</T>
				<br/>
				<T fontStyle='italic'>
					Keep your arms at your sides at all times, watch your feet and count your steps out loud.
					Once you begin, do not stop until you have completed the test. Do you understand?
				</T>
				<YesNoAnswerList answers={props.watInstructions} testId={WalkAndTurnTest.id} subjectName={subjectName} />
				<T variant='h6' component='h5'>During instruction: </T>
				<TableContainer>
					<Table>
						<TableHead sx={{ '& .MuiTableCell-head': {fontWeight: 700, borderBottom: 2, textTransform: 'uppercase', paddingBottom: 1} }}>
							<TableRow>
								<TableCell>Starting Indicators</TableCell>
								<TableCell sx={{textAlign: 'right'}}>To the Left</TableCell>
								<TableCell sx={{textAlign: 'right'}}>To the Right</TableCell>
								<TableCell sx={{textAlign: 'right'}}>Total</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>Stepped Down with Left Foot</TableCell>
								<TableCell sx={[{textAlign:'right'}, LeftFootLostLeftBalance.length > 0 && {fontWeight: 'bold'}]}>
									{LeftFootLostLeftBalance.length > 0 ? LeftFootLostLeftBalance.length : 0}
								</TableCell>
								<TableCell sx={[{textAlign: 'right'}, LeftFootLostRightBalance.length > 0 && {fontWeight: 'bold'}]}>
									{LeftFootLostRightBalance.length > 0 ? LeftFootLostRightBalance.length : 0}
								</TableCell>
								<TableCell sx={[{textAlign: 'right'}, (LeftFootLostLeftBalance.length > 0 || LeftFootLostRightBalance.length > 0) && {fontWeight: 'bold'}]}>
									{
										(LeftFootLostLeftBalance.length > 0 || LeftFootLostRightBalance.length > 0) ?
										LeftFootLostLeftBalance.length + LeftFootLostRightBalance.length
										: 0
									}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Stepped Down with Right Foot</TableCell>
								<TableCell sx={[{textAlign: 'right'}, RightFootLostLeftBalance.length > 0 && {fontWeight: 'bold'}]}>
									{RightFootLostLeftBalance.length > 0 ? RightFootLostLeftBalance.length : 0}
								</TableCell>
								<TableCell sx={[{textAlign: 'right'}, RightFootLostRightBalance.length > 0 && {fontWeight: 'bold'}]}>
									{RightFootLostRightBalance.length > 0 ? RightFootLostRightBalance.length : 0}
								</TableCell>
								<TableCell sx={[{textAlign:'right'}, (RightFootLostLeftBalance.length > 0 || RightFootLostRightBalance.length > 0) && {fontWeight:'bold'}]}>
									{
										(RightFootLostLeftBalance.length > 0 || RightFootLostRightBalance.length > 0) ?
										RightFootLostLeftBalance.length + RightFootLostRightBalance.length
										: 0
									}
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Started Too Soon</TableCell>
								<TableCell sx={{color:'text.disabled', textAlign:'right'}}>N/A</TableCell>
								<TableCell sx={{color:'text.disabled', textAlign:'right'}}>N/A</TableCell>
								<TableCell sx={[{textAlign:'right'}, WalkAndTurnTest.startedEarlyCount! > 0 && {fontWeight: 'bold'}]}>
									{WalkAndTurnTest.startedEarlyCount}
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
				<T sx={{mb:4, pt:4}}>
					<strong>Additional Notes: </strong>
					{WalkAndTurnTest.setupNotes ? WalkAndTurnTest.setupNotes : 'No additional notes'}
				</T>
				<WalkandTurnStepResults 
					title='First Set of Nine Heel-to-Toe Steps'
					stepAdjustment={0}
					stepsSet={firstSetSteps}
				/>
				{!WalkAndTurnTest.resetDate ?
					<>
						<T variant='h4' sx={{mb:4, pt:4}}>Turn</T>
						<T mb={3} pb={2}>
							<strong>{subjectName} </strong>
							performed
							<strong> {WalkAndTurnTest.incorrectTurn ? 'an incorrect turn' : 'a correct turn'}</strong>.
						</T>
						<T mb={4}>
							<strong>Turn Description: </strong>
							{WalkAndTurnTest.turnDescription || <T fontStyle='italic'>No turn description provided.</T>}
						</T>
					</>
					: <T sx={{mb:4, pt:4}}></T>
				}
				<WalkandTurnStepResults 
					title='Return Set of Nine Heel-to-Toe Steps'
					stepAdjustment={stepAdjustment!}
					stepsSet={returnSetSteps}
				/>
				{WalkAndTurnTest.resetDate ? (
					<>
						<T variant='h4' sx={{mb:4, pt:4}}>Restarted: </T>
						<T mb={3} pb={2}>
							The test was restarted at
							<strong> {new Date(WalkAndTurnTest.resetDate).toLocaleString()}</strong>
						</T>
						<T mb={4}>
							<strong>Restart Notes: </strong>
							{WalkAndTurnTest.resetNote || <T fontStyle='italic'>No restart notes added.</T>}
						</T>
					</>
				) : (
					<>
						<T variant='h4' sx={{mb:4, pt:4}}>Additional Info: </T>
						<T>
							<strong>Footwear: </strong>
							{WalkAndTurnTest.footwear !== undefined ? WalkAndTurnTest.footwear : <T fontStyle='italic'>No footwear found</T>}
						</T>
						<T>
							<strong>Additional Notes: </strong>
							{WalkAndTurnTest.testNotes || <T fontStyle='italic'>No additional notes added.</T>}
						</T>
						<T variant='h4' sx={{mb:4, pt:4}}>Decision Points: {WalkAndTurnTest.decisionPoint}/8</T>
					</>
				)}
			</>
		);

	}
	
	return (
		<>
			<T variant='h2'>5. Walk and Turn</T>
			{watTestResult.map((wat, index) => (
				<div className="pt-3" key={index}>
					{renderOneWalkAndTurnTest(wat, index)}
				</div>
			))}
			<T variant='h3'>Other Indicia</T>
			{submittedCase ?
				(
					<TextField
						multiline
						rows={9}
						fullWidth
						name="watReportAdditionalNotes"
						label="Post-Test Comments: "
						value={caseInfo.watReportAdditionalNotes}
						onChange={(evt: any) => props.onNotesChanged(evt)}
					/>					
				) :
				(
					<p>
						<strong>Post-Test Comments: </strong>						
						{caseInfo.watReportAdditionalNotes ? 
							<React.Fragment>
								{caseInfo.watReportAdditionalNotes}
								<DateUpdated dateUpdated={caseInfo.dateUpdated!} />
							</React.Fragment>
						:
						<T fontStyle='italic'>No post-test comments added.</T>
						}
					</p>
				)
			}
		</>
	);
}
export default CaseDetailWAT;