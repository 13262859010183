import React, { useContext, useEffect, useState } from 'react';

import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import T from '@mui/material/Typography';

import PickListContext from 'pickList/pickListContext';

export interface ICaseStatusFilter {
	statuses:string[]
}

interface IProps {
	onFilterChange: (statusFilter: string[]) => void;
}
const CaseStatusFilter = (props: IProps) => {
	
	const {pickListItems} = useContext(PickListContext);
	const [caseStatuses, setCaseStatuses] = useState<PickListItemResponseDTO[]>([]);
	const [filter, setFilter] = useState<ICaseStatusFilter>({statuses:[]});

	const {onFilterChange} = props;
	useEffect(() => {
		const caseStatuses = pickListItems.filter(pi => pi.pickListTypeCode === 'CaseStatus' && pi.isActive);
		setCaseStatuses(caseStatuses);

		const codes = caseStatuses.map(pi => pi.code!);		
		setFilter(prevFilter => ({...prevFilter, statuses: codes}));		
		onFilterChange(codes);
		
	}, [pickListItems, onFilterChange]);
	
	const onFilterCheckChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		let statuses = filter.statuses;

		const i = statuses.indexOf(e.target.name);

		if(!e.target.checked) {
			statuses.splice(i, 1); //remove the match.
		}
		else {		
			statuses.push(e.target.name);
		}
		setFilter({...filter, statuses });

		onFilterChange(statuses);
	};
	const isChecked = (code: string) => {
		return filter.statuses.includes(code);
	}

	return (
		<FormGroup sx={{mt:2}}>
			<T variant='h4' sx={{mb:0}}>Statuses</T>
			{caseStatuses.map(s => (
				<FormControlLabel
					label={s.description!}
					key={s.id!}
					control={<Checkbox
						name={s.code}
						checked={isChecked(s.code!)}
						onChange={onFilterCheckChanged}
					/>}
				/>
			))}
		</FormGroup>
	);
}
export default CaseStatusFilter;