import React from 'react';

import { gradient } from 'common/utils';
import debounce from 'lodash.debounce';
interface IProps {
	containerStyle?: any;
	heatMapData?: any[];
	fitBounds: boolean;
	searchPlace?: string;
	zoom?: number;

	onMapLoad?: (map: any) => any;
	passCoords?: any;
	id: string;
	options?: any;
	className?: any;
	currentCaseCoords?: any;
}

interface IState {
	loadMap: any ;
}

const googleString = 'google';

/**
 *
 *
 * @class Map, Followed https://cuneyt.aliustaoglu.biz/en/using-google-maps-in-react-without-custom-libraries/
 * @extends {Component}
 * @param {object} containerStyle
 * @param {array} heatMapData  [{lat, lng}]
 * @param {boolean} fitBounds
 * @param {string} searchPlace
 * @param {number} zoom default zoom is 12
 * @TODO for the future use, we can render some custom component on map see
 */

class Map extends React.Component<IProps, IState> {

	constructor(props: IProps) {
		super(props);
		this.state = {
			loadMap: null
		};
		this.onScriptLoad = this.onScriptLoad.bind(this);
	}
	public loadMap = () => {
		// key in the .env.local
		const mapkey = process.env.REACT_APP_GOOGLE_API_KEY_LOCAL || process.env.REACT_APP_GOOGLE_API_KEY;
		
		let googleWindowObj = (window as { [key: string]: any })[googleString] as string;
		
		if (!googleWindowObj) {
			const s = document.createElement('script');
			s.type = 'text/javascript';
			// s.crossOrigin=true;
			s.src = `https://maps.google.com/maps/api/js?key=${mapkey}&libraries=visualization,places`;

			const x = document.getElementsByTagName('script')[0];
			if (x.parentNode) { x.parentNode.insertBefore(s, x); }
			// Below is important.
			// We cannot access google.maps until it's finished loading
			s.addEventListener('load', e => {
				this.onScriptLoad();
			});
		} else {
			this.onScriptLoad();
		}
	}
	public componentDidMount = () => {
		this.loadMap();
		this.setState({
			loadMap: debounce(this.loadMap, 500)
		});
	}

	public componentDidUpdate = (prevProps: IProps) => {
		
		if (prevProps.heatMapData !== this.props.heatMapData) {
			this.loadMap();
		}
		if (prevProps.searchPlace !== this.props.searchPlace) {
			this.state.loadMap();
		}
	}

	public render() {
		const { containerStyle, id } = this.props;
		return (
			<div
				style={{
					height: '40vh',
					border: '1px solid rgba(10, 50, 50, 1)',
					...containerStyle
				}}

				id={id}
			/>
		);
	}

	private onScriptLoad = () => {
		const { heatMapData, onMapLoad, fitBounds, searchPlace, passCoords, zoom } = this.props;

		let googleWindowObj = (window as { [key: string]: any })[googleString] as any;

		// render Map
		const map = new googleWindowObj.maps.Map(document.getElementById(this.props.id), this.props.options);

		// TODO: Tried to use debounce here but not working.
		const createMarker = (place: any) => {
			const infowindow = new googleWindowObj.maps.InfoWindow();
			const marker = new googleWindowObj.maps.Marker({
				map,
				position: place.geometry.location
			});

			googleWindowObj.maps.event.addListener(marker, 'click', () => {
				infowindow.setContent(place.name);
				infowindow.open(map, this);
			});
			return marker;
		};
		// load heatMap data
		let loadedHeatMapData: any[] = [];
		if (heatMapData && Array.isArray(heatMapData) && heatMapData.length > 0) {
			loadedHeatMapData = heatMapData.reduce((prev, coords) => {
				if(coords.latitude !== 0 && coords.longitude !== 0) {
					prev.push(new googleWindowObj.maps.LatLng(coords.latitude, coords.longitude));
				}
				return prev;
			}, []);
		}
		if (googleWindowObj) {
			const heatmap = new googleWindowObj.maps.visualization.HeatmapLayer({
				data: loadedHeatMapData,
				gradient  // from utils.ts
			});
			heatmap.setMap(map);
		}

		// Search Places
		if (searchPlace && searchPlace.length > 0) {
			const request = {
				query: searchPlace,
				fields: ['name', 'geometry', 'formatted_address'],
			};
			map.zoom = zoom || 12;
			const service = new googleWindowObj.maps.places.PlacesService(map);

			service.findPlaceFromQuery(request, (results: any, status: any) => {
				if (status === googleWindowObj.maps.places.PlacesServiceStatus.OK) {
					for (const i of results) {
						createMarker(i);
					}
					if(passCoords) {
						const bounds = new googleWindowObj.maps.LatLngBounds();
						passCoords(bounds);
						// map.fitBounds(bounds)
					}
					map.setCenter(results[0].geometry.location);
				}
			});
		}
		else {
			// initial heatmaps, fit bounds
			// make bounds of heatmaps if there is no Searchplace text was input
			if (fitBounds && typeof fitBounds === 'boolean') {
				const bounds = new googleWindowObj.maps.LatLngBounds();
				for (let i = 0, LtLgLen = loadedHeatMapData.length; i < LtLgLen; i++) {
					bounds.extend(loadedHeatMapData[i]);
				}
				map.fitBounds(bounds);
			}
		}

		// if map bounds changed, pass data to reducer
		googleWindowObj.maps.event.addListener(map, 'bounds_changed', () => {
			if (map.getBounds() && passCoords) {
				const initialBounds = map.getBounds().toJSON();
				passCoords(initialBounds);
			}
		});

		// onMapLoad Callback
		if (onMapLoad && typeof onMapLoad === 'function') { onMapLoad(map); }
	}

}
export default Map;