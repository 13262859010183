import React from 'react';
import T from '@mui/material/Typography';

interface IProps {
	dateUpdated: string
}
const DateUpdated = (props: IProps) => {
	return (
		<T fontSize='small' fontStyle='italic' >{new Date(props.dateUpdated!).toLocaleString()}</T>
	);
}
export default DateUpdated;