import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const caseObservationsAPI = {
	getCaseObservation: (caseId: string) => {
		return axios.get(`${apiUrl}/cases/${caseId}/case-observation`)
			.then((response) => response.data as CaseObservationsDTO);
	},
	postCaseObservation: (request: PostCaseObservationsRequest) => {
		return axios.post(`${apiUrl}/cases/${request.caseId}/case-observation`, request)
			.then((response) => response.data as CaseObservationsDTO);
	},
	getSubjectInformation: (caseId: string) => {
		return axios.get(`${apiUrl}/cases/${caseId}/subject-information?format=json`)
			.then(response => response.data as SubjectInformationDTO);
	},
	postSubjectInformation: (request: PostCaseSubjectInformationRequest) => {
		return axios.post(`${apiUrl}/cases/${request.caseId}/subject-information?format=json`, request)
			.then(response => {
				return response.data as SubjectInformationDTO;
			})
			.catch(error => JSON.stringify(error));
	}
};

export default caseObservationsAPI;