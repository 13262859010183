import * as React from 'react';

import { Line } from 'react-chartjs-2';
// import {
// 	Chart as ChartJS,
// 	CategoryScale,
// 	LinearScale,
// 	PointElement,
// 	LineElement,
// 	Title,
// 	Tooltip,
// 	Legend,
//   } from 'chart.js';

import { months, } from 'common/utils';
import { statusColours } from 'report/reportColours';

import {DateTime} from 'luxon';

interface IProps {
	caseReports: CaseReportDTO[];
	height?: number;
	width?: number;
}

const getDataSets = (caseReports: CaseReportDTO[]) => {

	try {
		const labels: any[] = [];
		const result: any[] = [];

		const sortedCaseReports = caseReports.sort((a, b) => {
			const bm = DateTime.fromISO(b.date!);
			const am = DateTime.fromISO(a.date!);

			return am.diff(bm).milliseconds;
		});

		sortedCaseReports.forEach(c => {
			const index = labels.indexOf(c.status);
			const cDate = DateTime.fromISO(c.date!).toFormat('LL/dd/yyyy');
			let borderColor;
			if (c.status === 'Finalized') {
				borderColor = statusColours.finalized.main;
			} else if (c.status === 'Submitted') {
				borderColor = statusColours.submitted.main;
			} else if (c.status === 'Unsubmitted') {
				borderColor = statusColours.unsubmitted.main;
			} else if (c.status === 'Aborted') {
				borderColor = statusColours.aborted.main;
			} else if (c.status === 'Emergency Stopped') {
				borderColor = statusColours.emergencyStopped.main;
			}

			if (index < 0) {
				labels.push(c.status);
				result.push({
					label: c.status,
					fill: false,
					borderColor,
					lineTension: 0.2,
					type: 'line',
					pointHitRadius: 5,
					backgroundColor: borderColor,
					data: [{
						x: cDate,
						y: 1
					}]
				});
			} else {
				let dateIndex = -1;
				const existingDate = result[index].data.find((re: any, i: number) => {
					dateIndex = i; return re.x === cDate;
				});

				if (!existingDate) {
					result[index].data.push({
						x: cDate,
						y: 1
					});
				}
				else {
					existingDate.y = existingDate.y + 1;
					result[index].data[dateIndex] = existingDate;
				}
			}
		});
		return result;
	}
	catch (e) {
		// tslint:disable-next-line
		console.log(e);
		return [{
			label: '',
			data: [],
		}];
	}
};

export const getLabels = (d?: Date) => {
	// Set up the month labels starting from this month
	// const today = new Date();
	const today = d || new Date();
	let currentMonth = today.getMonth() + 1; // use next month as the first month
	const rollingMonths: string[] = [];

	for (let i = 0; i < 12; i++) {
		if (currentMonth > 11) {
			currentMonth = 0;
		}
		rollingMonths.push(months[currentMonth]);
		currentMonth++;
	}
	return rollingMonths;
};

const options = {
	responsive: true,
	aspectRatio: 2,
	// scales: {
	// 	xAxes: [{
	// 		type: 'time',
	// 	}],
	// }
};

const AssessmentStatusRawCases: React.FunctionComponent<IProps> = ({ caseReports }) => {

	const chartData = {
		labels: getLabels(),
		datasets: getDataSets(caseReports),
	};
	return (
		<div>
			<Line
				data={chartData}
				options={options}
			/>
		</div>
	);
};

export default AssessmentStatusRawCases;