import React, { useEffect, useState } from 'react';

import { statusColours } from './reportColours';
import { renderPercentage } from 'common/utils';

import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);

interface IProps {
	cases: CaseReportDTO[];
}
const StatusPieChart = (props: IProps) => {
	const { cases } = props;
	const [caseData, setCaseData] = useState<ChartData<"pie", number[], string>>({datasets: []});

	useEffect(() => {

		const data = cases.reduce((prev, c) => {
			switch (c.status?.toLowerCase()) {
				case 'finalized':
					prev[0]++;
					break;
				case 'submitted':
					prev[1]++;
					break;
				case 'unsubmitted':
					prev[2]++;
					break;
				case 'aborted':
					prev[3]++;
					break;
				case 'emergencystopped':
					prev[4]++;
					break;
			}

			return prev;
		}, [0,0,0,0,0]); //finalized, submitted, unsubmitted, aborted, emergencyStopped
		
		const labels = ['Finalized', 'Submitted', 'Unsubmitted', 'Aborted', 'Emergency Stopped'];
		// const sum = data.reduce((prev, val) => prev+val);
		// const labels = [`finalized: ${renderPercentage(data[0], sum)}`, `submitted: ${renderPercentage(data[1], sum)}`, `unsubmitted: ${renderPercentage(data[2], sum)}`, `aborted: ${renderPercentage(data[3], sum)}`, `emergencyStopped: ${renderPercentage(data[4], sum)}`];

		const bgColours = [statusColours.finalized.main, statusColours.submitted.main, statusColours.unsubmitted.main, statusColours.aborted.main, statusColours.emergencyStopped.main]

		setCaseData({
			labels,
			datasets: [
				{
					data,
					//lineTension: 0.1,
					backgroundColor: bgColours,
					borderColor: '#FFF',
				}
			],
		});
	}, [cases]);

	return (
		<Pie
			data={caseData}
		/>
	);
}
export default StatusPieChart;