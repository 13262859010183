import * as React from 'react';

import { Card, CardContent, Grid } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LockOpen from '@mui/icons-material/LockOpen';
import T from '@mui/material/Typography';
import { useEffect, useState } from 'react';

export interface IKPIData {
	count: number;
	arrests: number;
	submitted: number;
}
interface IProps {
	cases: CaseReportDTO[];
}
const KpiTable = (props:IProps) => {
	const {cases} = props;
	const [data, setData] = useState<IKPIData>({count: 0, arrests: 0, submitted: 0});

	useEffect(() => {
		const data = cases.reduce((prev, c) => {
			if(c.isArrested === true) {
				prev[0]++;
			}
			if(c.status?.toLowerCase() === 'submitted') {
				prev[1]++;
			}

			return prev;
		}, [0,0]);// arrests, submitted
		
		setData({ 
			count: cases.length,
			arrests: data[0],
			submitted: data[1]
		});
	}, [cases]);

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6} lg={4} >
				<Card variant='elevation' sx={{p:1, elevation:12}} >
					<CardContent>
						<T display='flex' alignItems='flex-end' fontSize='2rem'>
							<AssignmentIcon sx={{fontSize: '3rem'}} />
							Count
						</T>
						<T textAlign='center' fontWeight='700' fontSize='4rem'>{data.count}</T>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Card variant='elevation' sx={{p:1}} >
					<CardContent>
						<T display='flex' alignItems='flex-end' fontSize='2rem'>
							<AccountBalanceIcon sx={{fontSize: '3rem'}} />
							Arrests
						</T>
						<T textAlign='center' fontWeight='700' fontSize='4rem'>{data.arrests}</T>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={6} lg={4}>
				<Card variant='elevation' sx={{p:1}} >
					<CardContent>
						<T display='flex' alignItems='flex-end' fontSize='2rem'>
							<LockOpen sx={{fontSize: '3rem'}} />
							Submitted
						</T>
						<T textAlign='center' fontWeight='700'  fontSize='4rem'>{data.submitted}</T>
					</CardContent>
				</Card>
			</Grid>
		</Grid>

	);

};

export default KpiTable;