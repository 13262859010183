import * as React from 'react';
import RoomIcon from '@mui/icons-material/Room';
import Popover from '@mui/material/Popover';
import { Divider } from '@mui/material';
import T from '@mui/material/Typography';
import { Box } from '@mui/system';

interface IProps {
	lat: number;
	lng: number;
	caseIdentifier?: string;
	caseDate: string;
	officerName: string;
	finalized: boolean;
}
const CaseMapMarker = (props:IProps) => {
	const [anchorEl, setAnchorEl] = React.useState<SVGSVGElement | null>(null);

	const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
	  setAnchorEl(event.currentTarget);
	};
  
	const handleClose = () => {
	  setAnchorEl(null);
	};
  
	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	
	const color = props.finalized ? 'success' : 'error';

	return (
		<>
			<RoomIcon fontSize='large' color={color} onClick={handleClick} />
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				  }}
				>
				<T sx={{ p: 2 }}>
					{props.caseIdentifier}
					<Divider />
					<Box paddingTop={2}>{props.caseDate}</Box>
					<Box>{props.officerName}</Box>
				</T>
			</Popover>
		</>
	);
};

export default CaseMapMarker;