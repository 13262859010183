import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Alert, Autocomplete, Button, FormControlLabel, Grid, Snackbar, Switch, TextField } from '@mui/material';

import Page from 'components/common/Page';
import Title from 'components/common/Title';
import userAPI from './userAPI';
import authAPI from 'auth/authAPI';
import { Filter } from '@mui/icons-material';
import DistrictPlatoonLists from './DistrictPlatoonList';

const UserDetailPage = () => {

    const { userId } = useParams<{userId: string}>();
	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState<UserDetailsDTO>(initialUserValues);
	const [roles, setRoles] = useState<RoleDTO[]>([]);
	const [selectedRoles, setSelectedRoles] = useState<RoleDTO[]>([]);
	const [isFormValid, setIsFormValid] = useState(false);
	
	const [successIsOpen, setSuccessIsOpen] = useState(false);
	const [errorIsOpen, setErrorIsOpen] = useState(false);	
    const history = useHistory();

	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);

			let userProm:Promise<UserDetailsDTO>;
			if(userId === '0') {
				userProm = Promise.resolve(initialUserValues);
			}
			else {
				userProm = userAPI.getUser(userId);
			}
			const rolesProm = authAPI.getRoles();
			
			const user = await userProm;
			setUser(user);
			const roles = await rolesProm;
			setRoles(roles);

			const selectedRoles = roles.filter(r => user.roleIds?.includes(r.id!));
			setSelectedRoles(selectedRoles);

			setIsLoading(false);
		};
	
		fetchData();
	}, [userId]);

	//validate form data
	useEffect(() => {
		let isValid = true;
		
		isValid = isValid && (user.firstName!.length > 1);
		isValid = isValid && (user.lastName!.length > 1);
		isValid = isValid && (user.email!.length > 1);

		setIsFormValid(isValid);

	}, [user]);

	const handleTextChanges = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		setUser(u => ({...u, [e.target.name]: e.target.value}));
	}
	const handleCheckboxChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
		setUser(u => ({...u, [e.target.name]: e.target.checked}));
	}
	const handleRoleChanges = (event: React.SyntheticEvent<Element, Event>, value: RoleDTO[]) => {
		setSelectedRoles(value);
		
		const ids = value.map(r => r.id!);
		setUser(u => ({...u, roleIds:ids}));
	}
	const onDistrictChanged = (value: PickListItemResponseDTO|null) => {
		if(value) {
			setUser(u => ({...u, districtId:value.id}));
		}
		else {
			setUser(u => ({...u, districtId: undefined}));
		}
	}
	const onPlatoonChanged =  (value: PickListItemResponseDTO|null) => {
		if(value) {
			setUser(u => ({...u, platoonId:value.id}));
		}
		else {
			setUser(u => ({...u, platoonId: undefined}));
		}
	}

	const saveUser = async () => {
		setIsLoading(true);

		try {
			var res = await userAPI.saveUser(user);
			setUser(res);
			setSuccessIsOpen(true);

			setTimeout(() => {
				history.push('/admin/users');
			}, 1000);
		}
		catch(e) {
			console.error(e);
			setErrorIsOpen(true);
		}
		finally {
			setIsLoading(false);
		}
	}

	return (
		<Page>
			<Title>User Details</Title>
			<Grid container spacing={1}>
				<Grid item xs={12} sm={6} lg={3}>
					<TextField
						label="First Name"
						name="firstName"
						value={user?.firstName}
						onChange={handleTextChanges}
						fullWidth
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<TextField
						label="Last Name"
						name="lastName"
						value={user?.lastName}
						onChange={handleTextChanges}
						fullWidth
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<TextField
						label="Email"
						name="email"
						value={user?.email}
						onChange={handleTextChanges}
						fullWidth
						required
					/>
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<TextField
						label="Badge #"
						name="badgeNumber"
						value={user?.badgeNumber}
						onChange={handleTextChanges}
						fullWidth
						required
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<DistrictPlatoonLists user={user} onDistrictChanged={onDistrictChanged} onPlatoonChanged={onPlatoonChanged} />
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<Autocomplete
						options={roles}
						getOptionLabel={(option) => option.name!}
						multiple
						value={selectedRoles}
						onChange={handleRoleChanges}
						renderInput={(params) => (
							<TextField
							{...params}
							label="Roles"
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={6} lg={3}>
					<FormControlLabel
						label="Active"
						control={<Switch
							name="isActive"
							checked={user?.isActive}
							onChange={handleCheckboxChanges}
						/>}
						sx={{pl:3}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button variant='contained' color='secondary' onClick={saveUser} disabled={isLoading || !isFormValid}>Save</Button>
				</Grid>
			</Grid>

            <Snackbar open={successIsOpen} autoHideDuration={6000} onClose={() => setSuccessIsOpen(false)}>
                <Alert severity="success" onClose={() => setSuccessIsOpen(false)}>The user details were saved! Redirecting...</Alert>
            </Snackbar>
            <Snackbar open={errorIsOpen} autoHideDuration={6000} onClose={() => setErrorIsOpen(false)}>
                <Alert severity="error" onClose={() => setErrorIsOpen(false)}>There was an error saving!</Alert>
            </Snackbar>
		</Page>
	);
}
export default UserDetailPage;

const initialUserValues: UserDetailsDTO = {
	birthDate: '',
	email: '',
	firstName: '',
	gender: '',
	isActive: false,
	lastName: '',
	phoneNumber: '',
	roleIds: [],
	tenantId: 1,
	badgeNumber: '',
}

