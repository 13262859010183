import React, { useContext, useEffect, useState } from 'react';

import { Grid, TextField, } from '@mui/material';
import { GridColDef, GridRowParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid';
import DataGrid from 'components/common/StripedDataGrid';

import { DateTime } from 'luxon';
import AdapterLuxon from '@mui/lab/AdapterLuxon';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

import PickListContext from 'pickList/pickListContext';

import caseAPI from 'case/caseAPI';
import Page from 'components/common/Page';
import Title from 'components/common/Title';
import GoogleMap, {IMapCoords} from 'components/charts/GoogleMap/Googlemap';

interface IFilter {
	caseStartDate: DateTime | null;
	caseEndDate: DateTime | null;
}

const LocationsReportPage = () => {
	const { pickListItems } = useContext(PickListContext);
	const [isLoading, setIsLoading] = useState(true);
	
	const [filter, setFilter] = useState<IFilter>({caseStartDate: null, caseEndDate:null});
	const [mapCoords, setMapCoords] = useState<IMapCoords>();
	const [location, setLocation] = useState('');
	
	const [caseReports, setCaseReports] = useState<CaseReportDTO[]>([]);
	const [caseReportsFiltered, setCaseReportsFiltered] = useState<CaseReportDTO[]>([]);
	const [caseReportsFilteredForMap, setCaseReportsFilteredForMap] = useState<CaseReportDTO[]>([]);

	const [selectedRecord, setSelectedRecord] = useState<CaseReportDTO>();
	
	useEffect(() => {
		const fetchData = async () => {
			setIsLoading(true);

			const caseReports = await caseAPI.getCasesReports();
			setCaseReports(caseReports);

			setIsLoading(false);
		};
	
		fetchData();
	}, []);
	
	useEffect(() => {
		const filteredCaseReportsForMap = caseReports.filter(r => {
			let isMatch = true;

			if(r.latitude === null || r.latitude === 0
				|| r.longitude === null || r.longitude === 0) {
					return false;
				}

			if(r.date && filter.caseStartDate && filter.caseEndDate) {
				const caseDate = DateTime.fromISO(r.date);
				isMatch = isMatch && caseDate >= filter.caseStartDate && caseDate <= filter.caseEndDate;
			}
			else if(r.date && filter.caseStartDate) {
				const caseDate = DateTime.fromISO(r.date);
				isMatch = isMatch && caseDate >= filter.caseStartDate;

			}
			else if(r.date && filter.caseEndDate) {
				const caseDate = DateTime.fromISO(r.date);
				isMatch = isMatch && caseDate <= filter.caseEndDate;
			}
			
			return isMatch;
		});

		setCaseReportsFilteredForMap(filteredCaseReportsForMap);
		

	}, [filter, caseReports]);

	useEffect(() => {
		//sometimes the map returns '_gg {zb:fg, Ua: bg} rather than the coords, ignore this update
		if((mapCoords && mapCoords.east) || !mapCoords)
		{
			const filteredCaseReports = caseReports.filter(r => {
				let isMatch = true;			

				if(r.date && filter.caseStartDate && filter.caseEndDate) {
					const caseDate = DateTime.fromISO(r.date);
					isMatch = isMatch && caseDate >= filter.caseStartDate && caseDate <= filter.caseEndDate;
				}
				else if(r.date && filter.caseStartDate) {
					const caseDate = DateTime.fromISO(r.date);
					isMatch = isMatch && caseDate >= filter.caseStartDate;

				}
				else if(r.date && filter.caseEndDate) {
					const caseDate = DateTime.fromISO(r.date);
					isMatch = isMatch && caseDate <= filter.caseEndDate;
				}

				
				if(mapCoords) {
					isMatch = isMatch 
						&& (r.latitude! > mapCoords.south)
						&& (r.latitude! < mapCoords.north)
						&& (r.longitude! > mapCoords.west)
						&& (r.longitude! < mapCoords.east)
				}
				
				return isMatch;
			});
			setCaseReportsFiltered(filteredCaseReports);
		}

	}, [filter, mapCoords, caseReports])

	const onCoordsChange = (coords: IMapCoords) => {
		setMapCoords(coords);
	}

	
	const formatDate = (params: GridValueFormatterParams) => {
		if(params.value) {
			const formattedValue = DateTime.fromISO(params.value?.toString()).toLocaleString(DateTime.DATETIME_SHORT);
			return formattedValue;
		}
	}
	const getStatusDescription = (params: GridValueGetterParams) => {
		const plItem = pickListItems.find(pl => pl.id === params.row.statusId);
		return plItem ? plItem?.description : 'No data';
	}
	const columns: GridColDef[] = [
		{ field: 'caseIdentifier', headerName: 'Case #', flex: 1 },
		{ 
			field: 'statusId',
			headerName: 'State',
			valueGetter: getStatusDescription
		},
		{ field: 'officerName', headerName: 'Officer', flex: .8 },
		{ field: 'address', headerName: 'Location', flex:2,  },
		{ field: 'date', headerName: 'Date', valueFormatter:formatDate, width: 150 },
	];
	const onRowClick = (param: GridRowParams) => {
		setSelectedRecord(param.row);
		//as far as I can tell this never worked 
		//but the intention was to focus the map on the location when a grid item is clicked
		console.log(selectedRecord);
	}

	return (
		<Page isLoading={isLoading}>
			<Title>Test Locations</Title>
			<Grid container>
				<Grid container item xs={12} spacing={2}>
					<Grid item xs={12} sm={4} lg={3}>
					<TextField
						fullWidth
						margin='normal'
						name="location"
						label="Location"
						value={location}
						onChange={(e) => setLocation(e.target.value)}
					/>
					</Grid>
					<Grid item xs={12} sm={8} lg={9}>
					<LocalizationProvider dateAdapter={AdapterLuxon}>
						<DatePicker
							label="Case Start"
							value={filter.caseStartDate}
							onChange={(newValue) => { setFilter({...filter, caseStartDate: newValue! }); }}
							renderInput={(params) => <TextField {...params} margin='normal' />}
						/>
						<DatePicker							
							label="Case End"
							value={filter.caseEndDate}
							onChange={(newValue) => { setFilter({...filter, caseEndDate: newValue! }); }}
							renderInput={(params) => <TextField {...params} margin='normal' />}
						/>
					</LocalizationProvider>
					</Grid>
				</Grid>
				<Grid item xs={6} xl={5}>
					<GoogleMap
						containerStyle={{ width: '95%', height: '70vh', margin: '0 auto' }}
						heatMapData={caseReportsFilteredForMap}
						fitBounds={true}
						zoom={12}
						searchPlace={location}
						onCoordsChange={onCoordsChange}
					/>
				</Grid>
				<Grid item xs={6} xl={7}>
					<DataGrid
							rows={caseReportsFiltered}
							columns={columns}
							initialState={{
								pagination: {
									pageSize:10
								}
							}}
							autoHeight
							onRowClick={onRowClick}
							getRowClassName={p => p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
						/>
				</Grid>
			</Grid>

		</Page>
	);
}

export default LocationsReportPage;