import * as React from 'react';
import { Grid } from '@mui/material';
import T from '@mui/material/Typography';

interface IProps {
	caseInfo: CaseDTO;
	caseObservations: CaseObservationsDTO;
}

const CaseNarrativeDrivingAndStop: React.FC<IProps> = ( props: IProps ) => {
	const { caseInfo, caseObservations } = props;
	const formatRegex = /[A-Z]\S+/g;

	/*
	 * Exmaple usage: in props.caseObservations we have weatherRain = true, weatherSnow = true;
	 * calling convertBoolToFormattedString('weather') will return 'Rain, Snow'
	 */
	const convertBoolToFormattedString = (propNames: (keyof CaseObservationsDTO)[]) => {
		const result = propNames.reduce((acc, prop, i) => {
			if(typeof caseObservations[prop] === 'boolean' && caseObservations[prop] === true) {
				acc += `, ${prop.match(formatRegex)}`;
			}
			return acc;
		}, '');

		return result.length > 0 ? result.slice(2) : '';
	}

	return (
		<>
			<T variant='h2'>1. Driving and Stop Observations</T>
			<Grid container sx={{mb: 3, pb: 2}}>
				<Grid item xs={12} sx={{mb:3, pb:2}}>
					<T fontWeight="bold">Subject Driving and Stop Narrative:</T>
					{caseObservations.suspectDrivingAndStopNarrative || <T fontStyle="italic">No narrative added.</T>}
				</Grid>
				<Grid item xs={4} md={3} sx={{mb:3, pb:2}}>
					<T fontWeight="bold">Weather conditions:</T>
					{convertBoolToFormattedString(['weatherClear', 'weatherCloudy']) || <T fontStyle="italic">Not provided.</T>}
				</Grid>
				<Grid item xs={4} md={3} sx={{mb:3, pb:2}}>
					<T fontWeight="bold">Precipitation:</T>
					{convertBoolToFormattedString(['weatherRain', 'weatherSnow']) || 'None'}
				</Grid>
				<Grid item xs={4} md={3} sx={{mb:3, pb:2}}>
					<T fontWeight="bold">Lighting conditions:</T>
					{convertBoolToFormattedString(['lightingDawn', 'lightingDay', 'lightingDusk', 'lightingNight'])  || <T fontStyle="italic">Not provided.</T>}
				</Grid>
				<Grid item xs={4} md={3} sx={{mb:3, pb:2}}>
					<T fontWeight="bold">Road surface:</T>
					{convertBoolToFormattedString(['roadDry', 'roadWet', 'roadSnow', 'roadIce']) || <T fontStyle="italic">Not provided.</T>}
				</Grid>
				<Grid item xs={4} md={3} sx={{mb:3, pb:2}}>
					<T fontWeight="bold">Road level:</T>
					{convertBoolToFormattedString(['roadLevel', 'roadIncline']) || <T fontStyle="italic">Not provided.</T>}
				</Grid>
				<Grid item xs={12} sx={{mb:3, pb:2}}>
					<T fontWeight="bold">Location:</T>
					{caseInfo.address}
				</Grid>
				<Grid item xs={12} sx={{mb:3, pb:2}}>
					<T fontWeight="bold">Environmental Notes:</T>
					{caseObservations.environmentalNotes || <T fontStyle="italic">No environmental notes added.</T>}
				</Grid>
				<Grid item xs={12} sx={{mb:3, pb:2}}>
					<T fontWeight="bold">Location Notes:</T>
					{caseObservations.drivingAndStopNotes || <T fontStyle="italic">No location notes added.</T>}
				</Grid>
			</Grid>
		</>
	);
};

export default CaseNarrativeDrivingAndStop;