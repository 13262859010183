import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const horizontalGazeTestAPI = {
	getAllHorizontalGazeTests: () => {
		return axios.get(`${apiUrl}/horizontal-gaze-tests`)
			.then((response) => response.data as HorizontalGazeTestDTO[]);
	},
	getHorizontalGazeTest: (caseId: string) => {
		return axios.get(`${apiUrl}/cases/${caseId}/horizontal-gaze-test`)
			.then((response) => response.data as HorizontalGazeTestDTO[]);
	},
	postHorizontalGazeTest: (request: PostHorizontalGazeTestRequest) => {
		return axios.post(`${apiUrl}/cases/${request.caseId}/horizontal-gaze-test`, request)
			.then((response) => response.data as HorizontalGazeTestDTO);
	}
};

export default horizontalGazeTestAPI;