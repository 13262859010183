import { Button, Menu, MenuItem } from "@mui/material";
import Link from '@mui/material/Link';
import React from "react";

const ReportsMenu = () => {

  const [anchorElReports, setAnchorElReports] = React.useState<null | HTMLElement>(null);

  const handleOpenReportsMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElReports(event.currentTarget);
  };

  const handleCloseReportsMenu = () => {
    setAnchorElReports(null);
  };

  return (
    <React.Fragment>
      <Button key='Reports' onClick={handleOpenReportsMenu} sx={{ my: 2, color: 'white', display: 'block' }}>
        Reports
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorElReports}
        open={Boolean(anchorElReports)}
        onClose={handleCloseReportsMenu}
        MenuListProps={{ 'aria-labelledby': 'basic-button', }}
      >        
		<MenuItem onClick={handleCloseReportsMenu} component={Link} href='/reports/case-reports'>
			Case Reports
		</MenuItem>
		<MenuItem onClick={handleCloseReportsMenu} component={Link} href='/reports/summary-reports'>
			Test Summary
		</MenuItem>
		<MenuItem component={Link} href='/reports/location-reports'>
			Test Locations
		</MenuItem>
      </Menu>
    </React.Fragment>
  );
}

export default ReportsMenu;