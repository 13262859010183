import * as React from 'react';
import { Grid} from '@mui/material';
import T from '@mui/material/Typography';

interface IProps {
	caseInfo: CaseDTO;
	subjectInformation: SubjectInformationDTO;
	subjectName: string;
}

const CaseInitialInterview = ( props: IProps ) => {

	const { caseInfo,  subjectInformation, subjectName } = props;

	return (
		<>
			<T variant='h2'>3. Initial Interview</T>
			<T>
				This SFST was conducted by <strong>{caseInfo.officerName}</strong> of the York Regional Police Service.
					The Individual tested was <strong>{subjectName}</strong>, DOB
					<strong> {caseInfo.subjectDOB ? caseInfo.subjectDOB.substring(0, 10) : ''}</strong>.
			</T>
			<Grid container sx={{mt: 3, pt: 2}}>
				<Grid item xs={3}>
					<T fontWeight="bold">First Name</T>
					{subjectInformation.firstName || <T fontStyle="italic">No name provided.</T>}
				</Grid>
				<Grid item xs={3}>
					<T fontWeight="bold">Last Name</T>
					{subjectInformation.lastName || <T fontStyle="italic">No name provided.</T>}
				</Grid>
				<Grid item xs={3}>
					<T fontWeight="bold">License Type:</T>
					{subjectInformation.licenseType || <T fontStyle="italic">No type provided.</T>}
				</Grid>
				<Grid item xs={3}>
					<T fontWeight="bold">License Number:</T>
					{subjectInformation.licenseNumber || <T fontStyle="italic">No number provided.</T>}
				</Grid>
			</Grid>
			{(caseInfo.leadInvestigator !== caseInfo.officerName && caseInfo.leadInvestigator) &&
				<T sx={{mt:3, pt:2}}>
					<strong>{caseInfo.leadInvestigator || 'Lead Investigator'}</strong> of the York Regional Police Service notified 
					<strong> {caseInfo.officerName || 'Officer'}</strong> to conduct the SFST.
				</T>
			}
		</>
	);
};

export default CaseInitialInterview;
