import React from 'react';
import PickListContext from './pickListContext';
import pickListAPI from './pickListAPI';
import LoadingPage from 'components/common/LoadingPage';

const PickListStore = (props: any) => {
	const [pickListTypes, setPickListTypes] = React.useState([] as PickListType[]);
	const [pickListItems, setPickListItems] = React.useState([] as PickListItemResponseDTO[]);
	const [isInitializing, setIsInitializing] = React.useState(true);

	React.useEffect(() => {
		pickListAPI.getPickListItems()
			.then(items => setPickListItems(items))
			.then(() => pickListAPI.getPickListTypes()
				.then((items) => setPickListTypes(items)))
			.then(() => setIsInitializing(false));
	}, []);

	const filterByPickListItem = (picklistType: string) => (filter: any, row: any) => {
		const items = pickListItems.filter(pItem => pItem.pickListTypeCode === picklistType);
		const foundItem = items.find(pItem => pItem.description!.indexOf(filter.value) !== -1);
		if (filter.value === undefined && filter.value === '') {
			return true;
		}
		if (foundItem) {
			return row[filter.id] === foundItem!.id;
		}
		return false;
	};

	const setColumnValue = (picklistItemId: number) => {
		const pItemValue = pickListItems.find(item => item.id! === picklistItemId);
		if (pItemValue) {
			if (pItemValue.pickListTypeCode === 'SkillCategory') {
				const desc = pItemValue.description!.substring(0, pItemValue.description!.indexOf('-'));
				return desc;
			}
			return pItemValue.description;

		} else {
			return '';
		}
	};

	const initialState = {
		pickListTypes,
		pickListItems,
		isInitializing,
		filterByPickListItem,
		setColumnValue
	};



	return (
		<PickListContext.Provider value={initialState}>
			{initialState.isInitializing ? (<LoadingPage />) : props.children}
		</PickListContext.Provider>
	);
};
export default PickListStore;