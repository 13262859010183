import React from 'react';

import { greyScaleMapStyles, centerOfEdmonton } from 'common/utils';
import Map from './Map';

export interface IMapCoords {
	south: Number;
	west: Number;
	north: Number;
	east: Number;
}
interface IPropsGoogleMap {
	heatMapData?: any[];
	zoom?: number;
	containerStyle?: any;
	onMapLoad?: (map: any) => any;
	fitBounds: boolean;

	className?: any;
	searchPlace?: any;
	onCoordsChange?: (coords: IMapCoords) => void;
}
/**
 *
 *
 * @class   Googlemap,  Followed https://cuneyt.aliustaoglu.biz/en/using-google-maps-in-react-without-custom-libraries/
 * @param {array} heatMapData format:
 * 				[{lat:123.666, lng:123.666} , ...]
 * @param {number} zoom default zoom is 12
 * @param {object} containerStyle style object ;
 * @param {function} onMapLoad takes one argument:map , (map) => { do something with map}
 * @param {boolean} fitBounds {true} to show all the points after first time load, {false} it will render default center
 */
const Googlemap = (props: IPropsGoogleMap) => {
	
	const { zoom, containerStyle, heatMapData, onMapLoad, fitBounds, className, searchPlace } = props;
	const style = greyScaleMapStyles;

	return (
		<Map
			id="myMap"
			options={{
				center: centerOfEdmonton,
				zoom: 12,
				styles: style
			}}
			zoom={zoom}
			className={className}
			containerStyle={{ ...containerStyle }}
			onMapLoad={onMapLoad}
			fitBounds={fitBounds}
			searchPlace={searchPlace}
			heatMapData={heatMapData}
			passCoords={props.onCoordsChange}
		/>
	);
}

export default Googlemap;
