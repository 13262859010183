import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem, GridActionsColDef, GridColDef, GridRowId, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import DataGrid from 'components/common/StripedDataGrid';

import Page from 'components/common/Page';
import Title from 'components/common/Title';
import userAPI from './userAPI';
import { Box, Button, Link } from '@mui/material';

const UserListPage = () => {
	const history = useHistory();
	const [users, setUsers] = useState<UserDetailsDTO[]>([]);

	useEffect(() => {
		const fetchUsers = async () => {
			const users = await userAPI.getUsers();
			setUsers(users);
		};
	
		fetchUsers();
	}, []);
	
	const actions: GridActionsColDef =
	{
		field: 'actions',
		headerName: 'Actions',
		type: 'actions',
		getActions: (params:GridRowParams) => [
			<GridActionsCellItem onClick={() => goToEditPage(params.id)} label="Edit" showInMenu={false} icon={<EditIcon />} />
		]
	}
	const goToEditPage = (id:GridRowId)  => {
		history.push(`/admin/users/${id}`);
	}
	const columns: GridColDef[] = [
	{ field: 'id', headerName: 'ID', width: 50 },
	{
		field: 'name',
		headerName: 'Name',
		valueGetter: (p) => `${p.row.firstName} ${p.row.lastName}`,
		flex: 2,
	},
	{
		field: 'isActive',
		headerName: 'Status',
		valueGetter: (params: GridValueGetterParams) => params.row.isActive ? 'Active':'InActive',
		flex: 1
	},
	{
		field: 'email',
		headerName: 'Email',
		flex: 3
	},
	actions
	];

	return (
		<Page>
			<Title>
				<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
					<Box>
						Users
					</Box>
					<Box>
						<Button variant='contained' color='secondary' component={Link} href='/admin/users/0'>Add User</Button>
					</Box>
				</Box>
			</Title>
			<DataGrid
				rows={users}
				columns={columns}
				disableSelectionOnClick
				autoHeight
				getRowClassName={p => p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
			/>
		</Page>
	);
}
export default UserListPage;