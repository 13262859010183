import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem, GridActionsColDef, GridColDef, GridRowId, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import DataGrid from 'components/common/StripedDataGrid';
import Page from 'components/common/Page';
import Title from 'components/common/Title';
import pickListAPI from 'pickList/pickListAPI';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

const PickListItemListPage = () => {
	
    const { pickListTypeId } = useParams<{pickListTypeId: string}>();
	const [pickListType, setPickListType] = useState<PickListType>({});
	const [picklistItems, setPicklistItems] = useState<PickListItem[]>([]);
	const history = useHistory();

	useEffect(() => {
		const fetchData = async () => {
			if(pickListTypeId !== '0') {

				const typeProm = pickListAPI.getPickListType(pickListTypeId);
				const itemsProm = pickListAPI.getPickListItemByTypeId(pickListTypeId);

				const items = await itemsProm;
				setPicklistItems(items);

				const type = await typeProm;
				setPickListType(type);
			}
		};
	
		fetchData();
	}, [pickListTypeId]);
	
	const goToEditPage = (typeId: string, id:GridRowId)  => {
		history.push(`/admin/pick-lists/${typeId}/items/${id}`);
	}

	const actions: GridActionsColDef =
	{
		field: 'actions',
		headerName: 'Actions',
		type: 'actions',
		getActions: (params:GridRowParams) => [
			<GridActionsCellItem onClick={() => goToEditPage(pickListTypeId, params.id)} label="Edit" showInMenu={false} icon={<EditIcon />} />
		]
	}

	const columns: GridColDef[] = [
		{ field: 'id', headerName: 'ID', width: 90 },
		{
			field: 'code',
			headerName: 'Code',
			flex: 1
		},
		{
			field: 'description',
			headerName: 'Description',
			flex: 3
		},
		{
			field: 'isActive',
			headerName: 'Status',
			valueGetter: (params: GridValueGetterParams) => params.row.isActive ? 'Active':'InActive'
		},
		actions
	];

	//const { pickListType, pickListItems } = this.props;
	return (
			<Page>
				<Title>
					<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
						<Box>
							{`${pickListType.description} items`}
						</Box>
						<Box>
							<Button component={Link} variant='contained' color='secondary' href={`/admin/pick-lists/${pickListTypeId}/items/0`}>Add Item</Button>
						</Box>
					</Box>
				</Title>
				{/* <section className="features-1 text-left">
					<div className="container">
						<IconLinkButton to={`/admin/pick-lists/${this.props.match.params.pickListTypeId}/add-new-pick-list-item/0`}>Add PickList Item</IconLinkButton>
						<PickListItemList
							className="mt-5"
							pickListItems={pickListItems}
							onEdit={this.onPickListItemEdit}
						/>
					</div>
				</section> */}
				<DataGrid
					rows={picklistItems}
					columns={columns}
					disableSelectionOnClick
					autoHeight
					getRowClassName={p => p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
				/>
			</Page>
		);

	// private onPickListItemEdit = (pickListItemId: string) => {
	// 	this.props.history.push(`/admin/pick-lists/${this.props.match.params.pickListTypeId}/add-new-pick-list-item/${pickListItemId}`);
	// }

}
export default PickListItemListPage;
