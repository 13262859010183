import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const oneLegStandTestAPI = {
	getOneLegStandTest: (caseId: string) => {
		return axios.get(`${apiUrl}/cases/${caseId}/one-leg-stand-test`)
			.then((response) => response.data as OneLegStandTestDTO[]);
	},
	postOneLegStandTest: (request: PostOneLegStandTestRequest) => {
		return axios.post(`${apiUrl}/cases/${request.caseId}/one-leg-stand-test`, request)
			.then((response) => response.data as OneLegStandTestDTO);
	},
	getOneLegStandTestErrors: (caseId: string) => {
		return axios.get(`${apiUrl}/cases/${caseId}/one-leg-stand-test-errors`)
			.then((response) => response.data as OneLegStandTestErrorDTO[]);
	},
	getOneLegStandTestError: (caseId: string, id: number) => {
		return axios.get(`${apiUrl}/cases/${caseId}/one-leg-stand-test-errors/${id}`)
			.then((response) => response.data as OneLegStandTestErrorDTO);
	},
	postOneLegStandTestError: (request: PostOneLegStandTestErrorRequest) => {
		return axios.post(`${apiUrl}/cases/${request.caseId}/one-leg-stand-test-errors`.concat(request.id ? `/${request.id}` : ``), request)
			.then((response) => response.data as OneLegStandTestErrorDTO);
	}
};

export default oneLegStandTestAPI;