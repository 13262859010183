import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const yesNoRecordAPI = {
	getYesNoRecordItems: (caseId: string) => {
		return axios.get(`${apiUrl}/cases/${caseId}/yes-no-records`)
			.then((response) => response.data as YesNoRecordDTO[]);
	},
};

export default yesNoRecordAPI;