import React from 'react';

import { statusColours } from './reportColours';
import { months, sortDateStartingFromCurrent } from 'common/utils';

import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
  } from 'chart.js';


ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
  );

const getDataSets = (caseReports: CaseSummaryReportDTO[]) => {
	// Create a dataset for each of the status types
	try {
		const allDataSets = caseReports.map(caseSummary => {
			const currentMonth = new Date().getMonth();

			// if(currentMonth===1)
			const sortedMonths = sortDateStartingFromCurrent(currentMonth + 2);
			const resultMonth: number[] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
			// color
			let borderColor;
			switch(caseSummary.status?.toLowerCase()) {
				case 'finalized':
					borderColor = statusColours.finalized.main;
					break;
				case 'submitted':
					borderColor = statusColours.submitted.main;
					break;
				case 'unsubmitted':
					borderColor = statusColours.unsubmitted.main;
					break;
				case 'aborted':
					borderColor = statusColours.aborted.main;
					break;
				case 'emergency stopped':
					borderColor = statusColours.emergencyStopped.main;
					break;

			}

			// month
			const monthAndCounts: any[] = caseSummary.data || [];

			if (monthAndCounts.length > 0) {
				monthAndCounts.forEach(data => {
					const index = sortedMonths.indexOf(data.month);
					if (index >= 0) {
						resultMonth[index] = data.count;
					}
				});
			}
			return {
				label: caseSummary.status,
				data: resultMonth,
				borderColor,
				backgroundColor: borderColor,
				// pointHitRadius: 5,
				// type: 'line',
				// fill: false,
				// lineTension: 0.1,
			};
		});
		return allDataSets;
	}
	catch (e) {
		// tslint:disable-next-line
		console.log(e);
		return [{
			label: '',
			data: [],
		}];
	}
};

export const getLabels = (d?: Date) => {
	// Set up the month labels starting from this month
	// const today = new Date();
	const today = d || new Date();
	let currentMonth = today.getMonth() + 1; // use next month as the first month
	const rollingMonths: string[] = [];

	for (let i = 0; i < 12; i++) {
		if (currentMonth > 11) {
			currentMonth = 0;
		}
		rollingMonths.push(months[currentMonth]);
		currentMonth++;
	}
	return rollingMonths;
};

const options = {
	responsive: true,
	aspectRatio: 2,
};

interface IProps{
	cases: CaseSummaryReportDTO[];
}
const StatusLineGraph = (props: IProps) => {
	const chartData = {
		labels: getLabels(),
		datasets: getDataSets(props.cases),
	};

	return (
		<Line
			data={chartData}
			options={options}
		/>
	);
}
export default StatusLineGraph;