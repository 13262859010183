import React, { useEffect, useState } from 'react';

import { outcomeColours } from './reportColours';

import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData } from 'chart.js';
//import { renderPercentage } from 'common/utils';
ChartJS.register(ArcElement, Tooltip, Legend);

interface IProps {
	cases: CaseReportDTO[];// | CaseSummaryReportDTO[];
}
const ArrestedPieChart = (props: IProps) => {
	const { cases } = props;
	const [caseData, setCaseData] = useState<ChartData<"pie", number[], string>>({datasets: []});

	useEffect(() => {
		const data = cases.reduce((prev, c) => {
			if(c.isArrested === true) {
				prev[0]++;
			}
			else if(c.isArrested === false) {
				prev[1]++;
			}
			else {
				prev[2]++;
			}

			return prev;
		}, [0,0,0]);

		//const sum = data.reduce((prev, val) => prev+val);

		setCaseData({
			// labels: [
			// 	`Arrested: ${renderPercentage(data[0], sum)}`, 
			// 	`Not Arrested: ${renderPercentage(data[1], sum)}`,
			// 	`Unknown: ${renderPercentage(data[2], sum)}`,
			// ],
			labels: [
				'Arrested',
				'Not Arrested',
				'Unknown',
			],
			datasets: [
				{
					data: data,
					backgroundColor: [outcomeColours.arrested.main, outcomeColours.notArrested.main, outcomeColours.unKnown.main],
				}
			]
		});
		
	}, [cases]);

	return (
		<Pie
			data={caseData}
		/>
	);
}
export default ArrestedPieChart;