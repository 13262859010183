import React from 'react';

import { useHistory } from 'react-router-dom';

import { GridActionsCellItem, GridActionsColDef, GridColDef, GridRowId, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import DataGrid from 'components/common/StripedDataGrid';
import EditIcon from '@mui/icons-material/Edit';
import ListIcon from '@mui/icons-material/List';
import { Button, Link } from '@mui/material';
import { Box } from '@mui/system';

import pickListAPI from 'pickList/pickListAPI';
import Page from 'components/common/Page';
import Title from 'components/common/Title';

const PickListTypeListPage = () => {
	const [picklistTypes, setPicklistTypes] = React.useState<PickListType[]>([]);
	const history = useHistory();

	React.useEffect(() => {
		const fetchTypes = async () => {
			const types = await pickListAPI.getPickListTypes();
			setPicklistTypes(types);
		}

		fetchTypes();
	}, []);

	const goToEditPage = (id:GridRowId)  => {
		history.push(`/admin/pick-lists/${id}`);
	}
	const goToItemsPage = (id:GridRowId) => {
		history.push(`/admin/pick-lists/${id}/items`);
	}

	const actions: GridActionsColDef =
	{
		field: 'actions',
		headerName: 'Actions',
		type: 'actions',
		getActions: (params:GridRowParams) => [
			<GridActionsCellItem onClick={() => goToEditPage(params.id)} label="Edit" showInMenu={false} icon={<EditIcon />} />,
			<GridActionsCellItem onClick={() => goToItemsPage(params.id)} label="Items" showInMenu={false} icon={<ListIcon />} />
		]
	}

	const columns: GridColDef[] = [
	{ field: 'id', headerName: 'ID', width: 90 },
	{
		field: 'code',
		headerName: 'Code',
		flex: 1
	},
	{
		field: 'description',
		headerName: 'Description',
		flex: 3
	},
	{
		field: 'isActive',
		headerName: 'Status',
		valueGetter: (params: GridValueGetterParams) => params.row.isActive ? 'Active':'InActive'
	},
	actions
	];

	return (
		<Page>
			{/* <div style={{ height: 400, width: '100%' }}></div> */}
			<Title>
				<Box sx={{display: 'flex', justifyContent: 'space-between'}}>
					<Box>
						PickList Types
					</Box>
					<Box>
						<Button component={Link} variant='contained' color='secondary' href='/admin/pick-lists/0'>Add Type</Button>
					</Box>
				</Box>
			</Title>
			<DataGrid
				rows={picklistTypes}
				columns={columns}
				disableSelectionOnClick
				autoHeight
				getRowClassName={p => p.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
			/>
		</Page>
	);
}
export default PickListTypeListPage;