import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_URL;

const sfstAPI = {
	getSFSTSetup: (Id: string) => {
		return axios.get(`${apiUrl}/cases/${Id}/sfst-setup?format=json`)
			.then((response) => response.data as SFSTSetupDTO);
	},
	postSFSTSetup: (request: POSTSFSTSetupRequest) => {
		return axios.post(`${apiUrl}/cases${request.caseId !== undefined ? `/${request.caseId}` : ''}/sfst-setup?format=json`, request)
			.then((response) => response.data as SFSTSetupDTO);
	},
};

export default sfstAPI;