import React from 'react';
import { createTheme, PaletteColor, PaletteColorOptions } from '@mui/material/styles';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { LinkProps } from '@mui/material/Link';

import '@fontsource/inter';
import '@fontsource/saira';


const LinkBehavior = React.forwardRef<
  any,
  Omit<RouterLinkProps, 'to'> & { href: RouterLinkProps['to'] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

declare module '@mui/material/styles' {
	interface Palette {
		tertiary: PaletteColor;
	}
	interface PaletteOptions {
		tertiary: PaletteColorOptions;
	}
  }

// A custom theme for this app
const theme = createTheme({
	palette: {
		text: {
			primary: '#040336',
			secondary: '#ACBAC9',
		},
		primary: {
			main: '#040336',
			dark: '#010016',
			light: '#DDDDEA'
		},
		secondary: {
			main: '#FDC73B',
			dark: '#DBA419',
			light: '#FFF6E0',
		},
		tertiary: {
			main: '#446BC0',
			dark: '#123686',
			light: '#EAF0FD',
		},
		grey: {
			100: '#EEF1F4',
			200: '#DEE3E9',
			300: '#CDD6DE',
			400: '#BCC8D4',
			500: '#ACBAC9',
			600: '#8995A1',
			700: '#677078',
			800: '#454A50',
			900: '#222528',
		},
		info: {
			main: '#498CF1',
			light: '#DBE8FC',
			dark: '#3B70C1',
		},
		warning: {
			main: '#FCD611',
			light: '#FEF7CF',
			dark: '#C9AB0D',
		},
		success: {
			main: '#69C73C',
			light: '#E1F4D8',
			dark: '#549F30',
		},
		error: {
			main: '#DD3E24',
			light: '#F8D8D3',
			dark: '#B1321D',
		},
		background: {
			default: '#FAFAFA'
		}
	},
	typography: {
		fontFamily: '"Inter", "Roboto"',
		h1: {
			fontFamily: '"Saira ExtraBold", "Roboto"',
			fontSize: '3rem',
			fontWeight:700,
			marginBottom: 48,
			textTransform: 'uppercase',
			'@media print': {
				fontSize: '1.4rem',
			}
		},
		h2: {
			fontFamily: '"Saira ExtraBold", "Roboto"',
			fontSize: '2rem',
			fontWeight: 700,
			borderBottom: '2px solid #040336',
			marginBottom: 48,
			'@media print': {
				fontSize: '1.3rem',
				pageBreakAfter: 'avoid',
			}
		},
		h3: {
			fontSize: '1.75rem',
			fontWeight: 700,
			borderBottom: '1px solid #040336',
			marginBottom: 32,
			'@media print': {
				fontSize: '1.2rem',
			}
		},
		h4: {
			fontSize: '1.5rem',
			fontWeight: 700,
			marginBottom: 32,
			'@media print': {
				fontSize: '1.1rem',
			}
		},
		h6: {
			fontWeight: 700,
			'@media print': {
				fontSize: '1rem',
			}
		}
	},
	components: {
		MuiLink: {
		defaultProps: {
				component: LinkBehavior,
			} as LinkProps,
		},
		MuiButtonBase: {
			defaultProps: {
				LinkComponent: LinkBehavior,
			},
		},
		MuiGrid: {
			styleOverrides: {
				item: {
					'@media print': {
						marginBottom: 0,
					}
				}
			}
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					'@media print': {
						marginLeft: 0,
						marginRight: 0,
					}
				}
			}
		},
	},
});

export default theme;