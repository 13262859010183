import React from 'react';
import { Box, BoxProps, CircularProgress, Container } from '@mui/material';

interface IProps {
	className?: string;
	children?: {};
}

const Item = (props: BoxProps) => {
	const { sx, ...other } = props;
	return (
	  <Box
		sx={{
		  p: 1,
		  m: 1,
		  fontSize: '0.875rem',
		  fontWeight: '700',
		  ...sx,
		}}
		{...other}
	  />
	);
  }

const LoadingPage = ( props: IProps ) => {
	return (
		<Container maxWidth="md" sx={{height:'100vh;', display:'grid', alignContent: 'center'}}>
			<Box sx={{
				display: 'flex',
				alignItems: 'center',
				flexDirection: 'column',
				p: 1,
				m: 1,
				}}
			>
				<Item>
					Hold up, we are just getting everything ready.
				</Item>
				<Item>
					<CircularProgress />
				</Item>
			</Box>
		</Container>
	);
}

export default LoadingPage;