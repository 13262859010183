import theme from 'theme';

export const outcomeColours = {
	arrested: {
		main: theme.palette.primary.main,
	},
	notArrested: {
		main: theme.palette.tertiary.main,
	},
	unKnown: {
		main: theme.palette.grey[600],
	}
};

export const statusColours = {
	finalized:{ main: '#446bc0'},
	submitted: { main: '#fdc73b'},
	unsubmitted: { main: '#f5762e'},
	aborted: { main: '#040336'},
	emergencyStopped: { main: '#b6223c'},
};
