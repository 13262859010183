import React, { useContext } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";

import AuthStore from './auth/AuthStore';
import PickListStore from './pickList/pickListStore';
import Routes from './Routes';
import SettingsContext from 'settings/SettingsContext';


function App() {
	const { settings } = useContext(SettingsContext);
	const msalInstance = new PublicClientApplication({
		auth: {
			clientId: settings.b2CClientId!,
			authority: settings.b2CSignInPolicyURL,
			knownAuthorities: settings.b2CKnownAuthorities,
			redirectUri: window.location.origin,
		},
		cache: {
			cacheLocation: 'sessionStorage',
			storeAuthStateInCookie: false
		}
	});
	return (
		<MsalProvider instance={msalInstance}>
			<AuthStore>
				<PickListStore>
					<BrowserRouter>
					<Route component={Routes} />
					</BrowserRouter>
				</PickListStore>
			</AuthStore>
		</MsalProvider>
	);
}

export default App;