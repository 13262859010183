import React from 'react';

import Page from 'components/common/Page';
import Title from 'components/common/Title';
import T from '@mui/material/Typography';


const MarkPage = () => {

	return (
		<Page>
			<Title>Mark Page</Title>
			<T variant='h1'>The quick brown fox jumps over the lazy dog</T>
			<T variant='h2'>The quick brown fox jumps over the lazy dog</T>
		</Page>
	);
}
export default MarkPage;
