import { Box, Paper, SxProps, Theme, Toolbar } from '@mui/material';
import NavBar from 'components/navigation/NavBar';
import React from 'react';
import LoadingPage from './LoadingPage';

interface IProps {
	isLoading?: boolean;
    children?: React.ReactNode;
    /**
     * The system prop that allows defining system overrides as well as additional CSS styles.
     */
     sx?: SxProps<Theme>;
  }
const Page = (props: IProps) => {
    return (
		<>{
			props.isLoading ? <LoadingPage /> : 
			<>
			<Box display='flex' sx={{...props.sx, '@media print': {px:1} }}>
				<NavBar />
				<Paper sx={{p: 3, mb: 3, mx: 3, height:'100%', flexGrow:1,
					'@media print': {
							boxShadow:0, 
							borderRadius: 0, 
							backgroundColor: 'unset',
							px: 5
						}
					}}>
					<Toolbar /> {/* empty bar to set height offset */}
					{props.children}
				</Paper>
			</Box>
			</>
		}</>
	);
}
export default Page;